.alert-bar {
    background-color: $brand;
    padding: 20px 10px;
    position: relative;
    z-index: 51;
    display: flex;
    justify-content: center;
    align-items: center;
    .heading {
        font-family: $main-font;
        font-size: rem-calc(16);
        line-height: 1.5;
        color: $white;
        margin-bottom: 0;
        text-align: center;
    }
}
