.home-template {
	background-image: url('/images/hero/bg_refinery.jpg');
	.callouts {
		@include grid-row;
		margin-top: 69px;
		margin-bottom: 69px;
		.callout-container {
			@include grid-column;
			margin-bottom: 48px;
		}
	}
	.map-object {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 24px;
		margin-bottom: 48px;
		.inner {
			@include grid-row;
		}
		h2 {
			color: $brand-accent;
		}
		.text {
			@include grid-column;
			p {
				font-size: rem-calc(19);
			}
		}
		.map {
			@include grid-column;
			margin-bottom: 24px;
			img {
				margin-bottom: 1rem;
			}
		}
		.disclaimer {
			font-size: rem-calc(14);
			color: $brand-alt-accent;
		}
	}
	.safety-certifications {
		.inner {
			@include grid-row;
		}
		.media {
			ul {
				margin: 0;
				li {
					background: none;
				}
			}
			li {
				margin: 16px 32px 16px 0;
				padding-left: 0;
				display: inline-block;
				&::before {
					display: none;
				}
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.home-template {
		background-image: url('/images/hero/bg_refinery_med.jpg');
		.callouts {
			.callout-container {
				width: grid-column(6);
				margin-bottom: 0;
			}
		}
	}
}

@media screen and (min-width:50em) {
	.home-template {
		.map-object {
			max-width: 100%;
			margin-top: 140px;
			margin-bottom: 108px;
			.text {
				width: grid-column(4);
				@include grid-column-position(-8);
				p {
					font-size: rem-calc(18);
				}
			}
			.map {
				width: grid-column(8);
				@include grid-column-position(4);
				margin-bottom: 0;
			}
		}
		.hero {
			margin: 100px 0;
			p {
				font-size: rem-calc(18);
			}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.home-template {
		background-image: url('/images/hero/bg_refinery_lrg.jpg');
		.safety-certifications .media ul {
			margin-top: 24px;
		}
	}
}