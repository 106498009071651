@charset "UTF-8";
@font-face {
  font-family: "Neue Helvetica W01";
  src: url("/fonts/helvetica_neue_bold.woff2") format("woff2"), url("/fonts/helvetica_neue_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Neue Helvetica W01";
  src: url("/fonts/helvetica_neue_roman.woff2") format("woff2"), url("/fonts/helvetica_neue_roman.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Korolev W01";
  src: url("/fonts/korolev_condensed_bold.woff2") format("woff2"), url("/fonts/korolev_condensed_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Korolev W01";
  src: url("/fonts/korolev_condensed_medium.woff2") format("woff2"), url("/fonts/korolev_condensed_medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/
/*----------------------------------------------------------*\
    Mixins

    This file contains custom mixins. All mixins should go
    here as this file is imported into both the base and
    main css files.

\*----------------------------------------------------------*/
/*------------------------------------*\
    @group Social Media Link Colors
\*------------------------------------*/
/* @end Social Media Link Colors */
/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/
/**
 * Foundation for Sites by ZURB
 * Version 6.4.3
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
   ========================================================================== */
/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif; /* 1 */
  line-height: 1.15; /* 2 */
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0;
}

/**
 * Add the correct display in IE 9-.
 */
article,
aside,
footer,
header,
nav,
section {
  display: block;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
figcaption,
figure {
  display: block;
}

/**
 * Add the correct margin in IE 8.
 */
figure {
  margin: 1em 40px;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * Add the correct display in IE.
 */
main {
  display: block;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Links
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent; /* 1 */
  -webkit-text-decoration-skip: objects; /* 2 */
}

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none; /* 1 */
  text-decoration: underline; /* 2 */
  text-decoration: underline dotted; /* 2 */
}

/**
 * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
 */
b,
strong {
  font-weight: inherit;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font style in Android 4.3-.
 */
dfn {
  font-style: italic;
}

/**
 * Add the correct background and color in IE 9-.
 */
mark {
  background-color: #ff0;
  color: #000;
}

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block;
}

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none;
}

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 */
button {
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select { /* 1 */
  text-transform: none;
}

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button; /* 2 */
}

button,
[type=button],
[type=reset],
[type=submit] {
  /**
   * Remove the inner border and padding in Firefox.
   */
  /**
   * Restore the focus styles unset by the previous rule.
   */
}
button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Show the overflow in Edge.
 */
input {
  overflow: visible;
}

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type=checkbox],
[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type=search] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
  /**
   * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
   */
}
[type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Change the border, margin, and padding in all browsers (opinionated).
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  padding: 0; /* 3 */
  color: inherit; /* 2 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto;
}

/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE, and Firefox.
 */
details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}

/*
 * Add the correct display in IE 9-.
 */
menu {
  display: block;
}

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block;
}

/**
 * Add the correct display in IE.
 */
template {
  display: none;
}

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none;
}

.foundation-mq {
  font-family: "small=0em&medium=40em&large=64em&xlarge=75em&xxlarge=90em";
}

html {
  box-sizing: border-box;
  font-size: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  background: #1C1819;
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #E8EBF1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0;
}

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0;
}

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important;
}

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
}
[data-whatinput=mouse] button {
  outline: 0;
}

pre {
  overflow: auto;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

.is-visible {
  display: block !important;
}

.is-hidden {
  display: none !important;
}

.row {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.row::before, .row::after {
  display: table;
  content: " ";
}
.row::after {
  clear: both;
}
.row.collapse > .column, .row.collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.row .row {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .row .row {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.row .row.collapse {
  margin-right: 0;
  margin-left: 0;
}
.row.expanded {
  max-width: none;
}
.row.expanded .row {
  margin-right: auto;
  margin-left: auto;
}
.row:not(.expanded) .row {
  max-width: none;
}
.row.gutter-small > .column, .row.gutter-small > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.row.gutter-medium > .column, .row.gutter-medium > .columns {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
}

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .column, .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
  float: right;
}
.column.end:last-child:last-child, .end.columns:last-child:last-child {
  float: left;
}

.column.row.row, .row.row.columns {
  float: none;
}

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0;
}

.small-1 {
  width: 8.3333333333%;
}

.small-push-1 {
  position: relative;
  left: 8.3333333333%;
}

.small-pull-1 {
  position: relative;
  left: -8.3333333333%;
}

.small-offset-0 {
  margin-left: 0%;
}

.small-2 {
  width: 16.6666666667%;
}

.small-push-2 {
  position: relative;
  left: 16.6666666667%;
}

.small-pull-2 {
  position: relative;
  left: -16.6666666667%;
}

.small-offset-1 {
  margin-left: 8.3333333333%;
}

.small-3 {
  width: 25%;
}

.small-push-3 {
  position: relative;
  left: 25%;
}

.small-pull-3 {
  position: relative;
  left: -25%;
}

.small-offset-2 {
  margin-left: 16.6666666667%;
}

.small-4 {
  width: 33.3333333333%;
}

.small-push-4 {
  position: relative;
  left: 33.3333333333%;
}

.small-pull-4 {
  position: relative;
  left: -33.3333333333%;
}

.small-offset-3 {
  margin-left: 25%;
}

.small-5 {
  width: 41.6666666667%;
}

.small-push-5 {
  position: relative;
  left: 41.6666666667%;
}

.small-pull-5 {
  position: relative;
  left: -41.6666666667%;
}

.small-offset-4 {
  margin-left: 33.3333333333%;
}

.small-6 {
  width: 50%;
}

.small-push-6 {
  position: relative;
  left: 50%;
}

.small-pull-6 {
  position: relative;
  left: -50%;
}

.small-offset-5 {
  margin-left: 41.6666666667%;
}

.small-7 {
  width: 58.3333333333%;
}

.small-push-7 {
  position: relative;
  left: 58.3333333333%;
}

.small-pull-7 {
  position: relative;
  left: -58.3333333333%;
}

.small-offset-6 {
  margin-left: 50%;
}

.small-8 {
  width: 66.6666666667%;
}

.small-push-8 {
  position: relative;
  left: 66.6666666667%;
}

.small-pull-8 {
  position: relative;
  left: -66.6666666667%;
}

.small-offset-7 {
  margin-left: 58.3333333333%;
}

.small-9 {
  width: 75%;
}

.small-push-9 {
  position: relative;
  left: 75%;
}

.small-pull-9 {
  position: relative;
  left: -75%;
}

.small-offset-8 {
  margin-left: 66.6666666667%;
}

.small-10 {
  width: 83.3333333333%;
}

.small-push-10 {
  position: relative;
  left: 83.3333333333%;
}

.small-pull-10 {
  position: relative;
  left: -83.3333333333%;
}

.small-offset-9 {
  margin-left: 75%;
}

.small-11 {
  width: 91.6666666667%;
}

.small-push-11 {
  position: relative;
  left: 91.6666666667%;
}

.small-pull-11 {
  position: relative;
  left: -91.6666666667%;
}

.small-offset-10 {
  margin-left: 83.3333333333%;
}

.small-12 {
  width: 100%;
}

.small-offset-11 {
  margin-left: 91.6666666667%;
}

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%;
}
.small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
  clear: both;
}
.small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
  float: left;
}

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%;
}
.small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
  clear: both;
}
.small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
  float: left;
}

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.3333333333%;
}
.small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
  clear: both;
}
.small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
  float: left;
}

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%;
}
.small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
  clear: both;
}
.small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
  float: left;
}

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%;
}
.small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
  clear: both;
}
.small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
  float: left;
}

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.6666666667%;
}
.small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
  clear: both;
}
.small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
  float: left;
}

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.2857142857%;
}
.small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
  clear: both;
}
.small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
  float: left;
}

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%;
}
.small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
  clear: none;
}
.small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
  clear: both;
}
.small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
  float: left;
}

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0;
}
.small-collapse .row {
  margin-right: 0;
  margin-left: 0;
}

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0;
}

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}

.small-centered {
  margin-right: auto;
  margin-left: auto;
}
.small-centered, .small-centered:last-child:not(:first-child) {
  float: none;
  clear: both;
}

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0;
}

@media print, screen and (min-width: 40em) {
  .medium-1 {
    width: 8.3333333333%;
  }
  .medium-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .medium-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }
  .medium-offset-0 {
    margin-left: 0%;
  }
  .medium-2 {
    width: 16.6666666667%;
  }
  .medium-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .medium-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }
  .medium-offset-1 {
    margin-left: 8.3333333333%;
  }
  .medium-3 {
    width: 25%;
  }
  .medium-push-3 {
    position: relative;
    left: 25%;
  }
  .medium-pull-3 {
    position: relative;
    left: -25%;
  }
  .medium-offset-2 {
    margin-left: 16.6666666667%;
  }
  .medium-4 {
    width: 33.3333333333%;
  }
  .medium-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .medium-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .medium-5 {
    width: 41.6666666667%;
  }
  .medium-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .medium-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }
  .medium-offset-4 {
    margin-left: 33.3333333333%;
  }
  .medium-6 {
    width: 50%;
  }
  .medium-push-6 {
    position: relative;
    left: 50%;
  }
  .medium-pull-6 {
    position: relative;
    left: -50%;
  }
  .medium-offset-5 {
    margin-left: 41.6666666667%;
  }
  .medium-7 {
    width: 58.3333333333%;
  }
  .medium-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .medium-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .medium-8 {
    width: 66.6666666667%;
  }
  .medium-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .medium-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }
  .medium-offset-7 {
    margin-left: 58.3333333333%;
  }
  .medium-9 {
    width: 75%;
  }
  .medium-push-9 {
    position: relative;
    left: 75%;
  }
  .medium-pull-9 {
    position: relative;
    left: -75%;
  }
  .medium-offset-8 {
    margin-left: 66.6666666667%;
  }
  .medium-10 {
    width: 83.3333333333%;
  }
  .medium-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .medium-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .medium-11 {
    width: 91.6666666667%;
  }
  .medium-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .medium-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }
  .medium-offset-10 {
    margin-left: 83.3333333333%;
  }
  .medium-12 {
    width: 100%;
  }
  .medium-offset-11 {
    margin-left: 91.6666666667%;
  }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
    float: left;
  }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
    float: left;
  }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
    float: left;
  }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
    float: left;
  }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
    float: left;
  }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
    float: left;
  }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
    float: left;
  }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
    float: left;
  }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .medium-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .medium-centered, .medium-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}
@media print, screen and (min-width: 64em) {
  .large-1 {
    width: 8.3333333333%;
  }
  .large-push-1 {
    position: relative;
    left: 8.3333333333%;
  }
  .large-pull-1 {
    position: relative;
    left: -8.3333333333%;
  }
  .large-offset-0 {
    margin-left: 0%;
  }
  .large-2 {
    width: 16.6666666667%;
  }
  .large-push-2 {
    position: relative;
    left: 16.6666666667%;
  }
  .large-pull-2 {
    position: relative;
    left: -16.6666666667%;
  }
  .large-offset-1 {
    margin-left: 8.3333333333%;
  }
  .large-3 {
    width: 25%;
  }
  .large-push-3 {
    position: relative;
    left: 25%;
  }
  .large-pull-3 {
    position: relative;
    left: -25%;
  }
  .large-offset-2 {
    margin-left: 16.6666666667%;
  }
  .large-4 {
    width: 33.3333333333%;
  }
  .large-push-4 {
    position: relative;
    left: 33.3333333333%;
  }
  .large-pull-4 {
    position: relative;
    left: -33.3333333333%;
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .large-5 {
    width: 41.6666666667%;
  }
  .large-push-5 {
    position: relative;
    left: 41.6666666667%;
  }
  .large-pull-5 {
    position: relative;
    left: -41.6666666667%;
  }
  .large-offset-4 {
    margin-left: 33.3333333333%;
  }
  .large-6 {
    width: 50%;
  }
  .large-push-6 {
    position: relative;
    left: 50%;
  }
  .large-pull-6 {
    position: relative;
    left: -50%;
  }
  .large-offset-5 {
    margin-left: 41.6666666667%;
  }
  .large-7 {
    width: 58.3333333333%;
  }
  .large-push-7 {
    position: relative;
    left: 58.3333333333%;
  }
  .large-pull-7 {
    position: relative;
    left: -58.3333333333%;
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .large-8 {
    width: 66.6666666667%;
  }
  .large-push-8 {
    position: relative;
    left: 66.6666666667%;
  }
  .large-pull-8 {
    position: relative;
    left: -66.6666666667%;
  }
  .large-offset-7 {
    margin-left: 58.3333333333%;
  }
  .large-9 {
    width: 75%;
  }
  .large-push-9 {
    position: relative;
    left: 75%;
  }
  .large-pull-9 {
    position: relative;
    left: -75%;
  }
  .large-offset-8 {
    margin-left: 66.6666666667%;
  }
  .large-10 {
    width: 83.3333333333%;
  }
  .large-push-10 {
    position: relative;
    left: 83.3333333333%;
  }
  .large-pull-10 {
    position: relative;
    left: -83.3333333333%;
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .large-11 {
    width: 91.6666666667%;
  }
  .large-push-11 {
    position: relative;
    left: 91.6666666667%;
  }
  .large-pull-11 {
    position: relative;
    left: -91.6666666667%;
  }
  .large-offset-10 {
    margin-left: 83.3333333333%;
  }
  .large-12 {
    width: 100%;
  }
  .large-offset-11 {
    margin-left: 91.6666666667%;
  }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%;
  }
  .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
    clear: both;
  }
  .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
    float: left;
  }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%;
  }
  .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
    clear: both;
  }
  .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
    float: left;
  }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.3333333333%;
  }
  .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
    clear: both;
  }
  .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
    float: left;
  }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%;
  }
  .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
    clear: both;
  }
  .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
    float: left;
  }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%;
  }
  .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
    clear: both;
  }
  .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
    float: left;
  }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.6666666667%;
  }
  .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
    clear: both;
  }
  .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
    float: left;
  }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.2857142857%;
  }
  .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
    clear: both;
  }
  .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
    float: left;
  }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%;
  }
  .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
    clear: none;
  }
  .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
    clear: both;
  }
  .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
    float: left;
  }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0;
  }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0;
  }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0;
  }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
  .large-centered {
    margin-right: auto;
    margin-left: auto;
  }
  .large-centered, .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0;
  }
}
.column-block {
  margin-bottom: 1.25rem;
}
.column-block > :last-child {
  margin-bottom: 0;
}
@media print, screen and (min-width: 40em) {
  .column-block {
    margin-bottom: 1.875rem;
  }
  .column-block > :last-child {
    margin-bottom: 0;
  }
}

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
}

em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: 700;
  line-height: inherit;
}

small {
  font-size: 80%;
  line-height: inherit;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Korolev W01", Impact, sans-serif;
  font-style: normal;
  font-weight: 500;
  color: inherit;
  text-rendering: optimizeLegibility;
}
h1 small, .h1 small,
h2 small, .h2 small,
h3 small, .h3 small,
h4 small, .h4 small,
h5 small, .h5 small,
h6 small, .h6 small {
  line-height: 0;
  color: #414247;
}

h1, .h1 {
  font-size: 3rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h2, .h2 {
  font-size: 2.5rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h3, .h3 {
  font-size: 2.0625rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h4, .h4 {
  font-size: 1.75rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h5, .h5 {
  font-size: 1.0625rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0.5rem;
}

@media print, screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 4.3125rem;
  }
  h2, .h2 {
    font-size: 3.4375rem;
  }
  h3, .h3 {
    font-size: 2.4375rem;
  }
  h4, .h4 {
    font-size: 2.1875rem;
  }
  h5, .h5 {
    font-size: 1.25rem;
  }
  h6, .h6 {
    font-size: 1rem;
  }
}
a {
  line-height: inherit;
  color: #2199e8;
  text-decoration: none;
  cursor: pointer;
}
a:hover, a:focus {
  color: #1585cf;
}
a img {
  border: 0;
}

hr {
  clear: both;
  max-width: 62.5rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #414247;
  border-left: 0;
}

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6;
}

li {
  font-size: inherit;
}

ul {
  margin-left: 1.25rem;
  list-style-type: disc;
}

ol {
  margin-left: 1.25rem;
}

ul ul, ul ol, ol ul, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

dl {
  margin-bottom: 1rem;
}
dl dt {
  margin-bottom: 0.3rem;
  font-weight: 700;
}

blockquote {
  margin: 0 0 1rem;
  padding: 0 1.25rem 0 0;
  border-left: none;
}
blockquote, blockquote p {
  line-height: 1.6;
  color: #FFFFFF;
}

cite {
  display: block;
  font-size: 0.8125rem;
  color: #1C1819;
}
cite:before {
  content: "— ";
}

abbr, abbr[title] {
  border-bottom: 1px dotted #000000;
  cursor: help;
  text-decoration: none;
}

figure {
  margin: 0;
}

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #414247;
  background-color: #C4CDCF;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: 400;
  color: #000000;
}

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #C4CDCF;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000000;
}

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.4;
  color: #1C1819;
}

.lead {
  font-size: 125%;
  line-height: 1.6;
}

.stat {
  font-size: 2.5rem;
  line-height: 1;
}
p + .stat {
  margin-top: -1rem;
}

ul.no-bullet, ol.no-bullet {
  margin-left: 0;
  list-style: none;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 40em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}
@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}
.show-for-print {
  display: none !important;
}

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important;
  }
  .show-for-print {
    display: block !important;
  }
  .hide-for-print {
    display: none !important;
  }
  table.show-for-print {
    display: table !important;
  }
  thead.show-for-print {
    display: table-header-group !important;
  }
  tbody.show-for-print {
    display: table-row-group !important;
  }
  tr.show-for-print {
    display: table-row !important;
  }
  td.show-for-print {
    display: table-cell !important;
  }
  th.show-for-print {
    display: table-cell !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
  }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  pre,
  blockquote {
    border: 1px solid #1C1819;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  .print-break-inside {
    page-break-inside: auto;
  }
}
[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 2px solid #E8EBF1;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #414247;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none;
}
[type=text]:focus, [type=password]:focus, [type=date]:focus, [type=datetime]:focus, [type=datetime-local]:focus, [type=month]:focus, [type=week]:focus, [type=email]:focus, [type=number]:focus, [type=search]:focus, [type=tel]:focus, [type=time]:focus, [type=url]:focus, [type=color]:focus,
textarea:focus {
  outline: none;
  border: 1px solid #1C1819;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #414247;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}

textarea {
  max-width: 100%;
}
textarea[rows] {
  height: auto;
}

input::placeholder,
textarea::placeholder {
  color: #414247;
}
input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #C4CDCF;
  cursor: default;
}

[type=submit],
[type=button] {
  appearance: none;
  border-radius: 0;
}

input[type=search] {
  box-sizing: border-box;
}

[type=file],
[type=checkbox],
[type=radio] {
  margin: 0 0 1rem;
}

[type=checkbox] + label,
[type=radio] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0;
}
[type=checkbox] + label[for],
[type=radio] + label[for] {
  cursor: pointer;
}

label > [type=checkbox],
label > [type=radio] {
  margin-right: 0.5rem;
}

[type=file] {
  width: 100%;
}

label {
  display: block;
  margin: 0;
  font-size: 0.6875rem;
  font-weight: 700;
  line-height: 1.09;
  color: #979DA8;
}
label.middle {
  margin: 0 0 1rem;
  padding: 0.625rem 0;
}

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000000;
}

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem;
}
.input-group > :first-child {
  border-radius: 4px 0 0 4px;
}
.input-group > :last-child > * {
  border-radius: 0 4px 4px 0;
}

.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label, .input-group-button, .input-group-field, .input-group-label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle;
}

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #414247;
  background: #C4CDCF;
  color: #000000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%;
}
.input-group-label:first-child {
  border-right: 0;
}
.input-group-label:last-child {
  border-left: 0;
}

.input-group-field {
  border-radius: 0;
  height: 2.5rem;
}

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%;
}
.input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  height: 2.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1rem;
}

.input-group .input-group-button {
  display: table-cell;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
}

legend {
  max-width: 100%;
  margin-bottom: 0.5rem;
}

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #414247;
}
.fieldset legend {
  margin: 0;
  margin-left: -0.1875rem;
  padding: 0 0.1875rem;
}

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 2px solid #E8EBF1;
  border-radius: 4px;
  background-color: #FFFFFF;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #414247;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%2828, 24, 25%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
@media screen and (min-width: 0\0 ) {
  select {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg==");
  }
}
select:focus {
  outline: none;
  border: 1px solid #1C1819;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px #414247;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
}
select:disabled {
  background-color: #C4CDCF;
  cursor: default;
}
select::-ms-expand {
  display: none;
}
select[multiple] {
  height: auto;
  background-image: none;
}

.is-invalid-input:not(:focus) {
  border-color: #ec5840;
  background-color: #fdeeec;
}
.is-invalid-input:not(:focus)::placeholder {
  color: #ec5840;
}

.is-invalid-label {
  color: #ec5840;
}

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: #ec5840;
}
.form-error.is-visible {
  display: block;
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}
.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #FFFFFF;
  user-select: none;
}

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0;
}

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #414247;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer;
}
input + .switch-paddle {
  margin: 0;
}
.switch-paddle::after {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  transform: translate3d(0, 0, 0);
  border-radius: 0;
  background: #FFFFFF;
  transition: all 0.25s ease-out;
  content: "";
}
input:checked ~ .switch-paddle {
  background: #2199e8;
}
input:checked ~ .switch-paddle::after {
  left: 2.25rem;
}
[data-whatinput=mouse] input:focus ~ .switch-paddle {
  outline: 0;
}

.switch-inactive, .switch-active {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.switch-active {
  left: 8%;
  display: none;
}
input:checked + label > .switch-active {
  display: block;
}

.switch-inactive {
  right: 15%;
}
input:checked + label > .switch-inactive {
  display: none;
}

.switch.tiny {
  height: 1.5rem;
}
.switch.tiny .switch-paddle {
  width: 3rem;
  height: 1.5rem;
  font-size: 0.625rem;
}
.switch.tiny .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1rem;
  height: 1rem;
}
.switch.tiny input:checked ~ .switch-paddle::after {
  left: 1.75rem;
}

.switch.small {
  height: 1.75rem;
}
.switch.small .switch-paddle {
  width: 3.5rem;
  height: 1.75rem;
  font-size: 0.75rem;
}
.switch.small .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}
.switch.small input:checked ~ .switch-paddle::after {
  left: 2rem;
}

.switch.large {
  height: 2.5rem;
}
.switch.large .switch-paddle {
  width: 5rem;
  height: 2.5rem;
  font-size: 1rem;
}
.switch.large .switch-paddle::after {
  top: 0.25rem;
  left: 0.25rem;
  width: 2rem;
  height: 2rem;
}
.switch.large input:checked ~ .switch-paddle::after {
  left: 2.75rem;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0;
}
thead,
tbody,
tfoot {
  border: 1px solid #f2f2f2;
  background-color: #FFFFFF;
}

caption {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: 700;
}

thead {
  background: #f9f9f9;
  color: #E8EBF1;
}

tfoot {
  background: #f2f2f2;
  color: #E8EBF1;
}

thead tr,
tfoot tr {
  background: transparent;
}
thead th,
thead td,
tfoot th,
tfoot td {
  padding: 0.5rem 0.625rem 0.625rem;
  font-weight: 700;
  text-align: left;
}

tbody th,
tbody td {
  padding: 0.5rem 0.625rem 0.625rem;
}

tbody tr:nth-child(even) {
  border-bottom: 0;
  background-color: #f2f2f2;
}

table.unstriped tbody {
  background-color: #FFFFFF;
}
table.unstriped tbody tr {
  border-bottom: 0;
  border-bottom: 1px solid #f2f2f2;
  background-color: #FFFFFF;
}

@media screen and (max-width: 63.9375em) {
  table.stack thead {
    display: none;
  }
  table.stack tfoot {
    display: none;
  }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block;
  }
  table.stack td {
    border-top: 0;
  }
}

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto;
}

table.hover thead tr:hover {
  background-color: #f4f4f4;
}
table.hover tfoot tr:hover {
  background-color: #ededed;
}
table.hover tbody tr:hover {
  background-color: #fafafa;
}
table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed;
}

.table-scroll {
  overflow-x: auto;
}
.table-scroll table {
  width: auto;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 39.9375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 40em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 40em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 40em) and (max-width: 63.9375em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 39.9375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.9375em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}
@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}
@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}
@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}
@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: " ";
}
.clearfix::after {
  clear: both;
}

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0);
}

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%);
}

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%);
}

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity;
}

.fade-in.mui-enter.mui-enter-active {
  opacity: 1;
}

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity;
}

.fade-out.mui-leave.mui-leave-active {
  opacity: 0;
}

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0;
}

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1;
}

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0;
}

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0;
}

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0;
}

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1;
}

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0;
}

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0;
}

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1;
}

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0;
}

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1;
}

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0;
}

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0;
}

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0;
}

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1;
}

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1;
}

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0;
}

.slow {
  transition-duration: 750ms !important;
}

.fast {
  transition-duration: 250ms !important;
}

.linear {
  transition-timing-function: linear !important;
}

.ease {
  transition-timing-function: ease !important;
}

.ease-in {
  transition-timing-function: ease-in !important;
}

.ease-out {
  transition-timing-function: ease-out !important;
}

.ease-in-out {
  transition-timing-function: ease-in-out !important;
}

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  transition-delay: 300ms !important;
}

.long-delay {
  transition-delay: 700ms !important;
}

.shake {
  animation-name: shake-7;
}
@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%);
  }
}

.spin-cw {
  animation-name: spin-cw-1turn;
}
@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn);
  }
  100% {
    transform: rotate(0);
  }
}

.spin-ccw {
  animation-name: spin-cw-1turn;
}
@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
}

.wiggle {
  animation-name: wiggle-7deg;
}
@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg);
  }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg);
  }
  0%, 30%, 70%, 100% {
    transform: rotate(0);
  }
}

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms;
}

.infinite {
  animation-iteration-count: infinite;
}

.slow {
  animation-duration: 750ms !important;
}

.fast {
  animation-duration: 250ms !important;
}

.linear {
  animation-timing-function: linear !important;
}

.ease {
  animation-timing-function: ease !important;
}

.ease-in {
  animation-timing-function: ease-in !important;
}

.ease-out {
  animation-timing-function: ease-out !important;
}

.ease-in-out {
  animation-timing-function: ease-in-out !important;
}

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important;
}

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important;
}

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important;
}

.short-delay {
  animation-delay: 300ms !important;
}

.long-delay {
  animation-delay: 700ms !important;
}

/* Magnific Popup CSS */
/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 1;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #61B3E4;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #61B3E4;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/
body {
  background: #201e1e;
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
  color: #E8EBF1;
  padding-top: 72px;
}
@media screen and (min-width: 40em) {
  body {
    font-size: 1.125rem;
    padding-top: 92px;
  }
}

.bg-template {
  background: no-repeat center top #1C1819;
}

p {
  font-size: 1em;
  line-height: 1.5;
  margin: 0 0 24px;
}
@media screen and (min-width: 40em) {
  p {
    margin: 0 0 27px;
  }
}
p.intro {
  font-size: 1.1875rem;
}

a {
  color: #8CAB14;
  transition: all 0.3s;
  text-decoration: none;
}
a:hover, a:focus {
  color: #61B3E4;
  text-decoration: none;
}

hr {
  margin: 1.25rem 0;
}

@media screen and (min-width: 40em) {
  p.intro {
    font-size: 1.4375rem;
  }
}
/* @end */
/*----------------------------------------------------------*\
   Headings

   The headings follow a modular scale and vertical rhythm
   that changes between small and larger views. To keep
   your headings semantic, you can apply the heading classes
   to different headings for stylistic purposes while
   still using the appropriate heading hierarchy in your
   HTML.

\*----------------------------------------------------------*/
h1, .h1 {
  font-size: 3rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1.1;
  margin: 0 0 19px;
}

h2, .h2 {
  font-size: 2.5rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1.1;
  margin: 0 0 16px;
}

h3, .h3 {
  font-size: 2.0625rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1.09;
  margin: 0 0 24px;
}

h4, .h4 {
  font-size: 1.75rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1.11;
  margin: 0 0 17px;
}

h1.alt, h2.alt, h3.alt, h4.alt, h5.alt {
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
}

@media screen and (min-width: 40em) {
  h1, .h1 {
    font-size: 4.3125rem;
    letter-spacing: -1px;
    line-height: 1.06;
    margin: 0 0 21px;
  }
  h2, .h2 {
    font-size: 3.4375rem;
    line-height: 1.05;
    margin: 0 0 23px;
  }
  h3, .h3 {
    font-size: 2.4375rem;
    line-height: 1.08;
    margin: 0 0 18px;
  }
  h4, .h4 {
    font-size: 2.1875rem;
    line-height: 1.06;
    margin: 0 0 18px;
  }
}
/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/
ul {
  padding: 0;
  margin: 24px 0;
}
@media screen and (min-width: 40em) {
  ul {
    margin: 27px 0;
  }
}
ul li {
  background: url("/images/icons/primitive-dot.svg") no-repeat left 3px;
  background-size: 12px 12px;
  line-height: 1.5;
  padding: 0 0 0 15px;
  margin: 0 0 0.5em;
  list-style: none;
  position: relative;
}
ul li:last-child {
  margin-bottom: 0 !important;
}
ul li ul {
  margin: 0.666em 0 0.75em;
}

ol {
  margin: 24px 0 27px 15px;
}
@media screen and (min-width: 40em) {
  ol {
    margin: 27px 0 27px 15px;
  }
}
ol li {
  line-height: 1.5;
  margin: 0 0 14px;
  counter-increment: list;
  list-style-type: none;
  position: relative;
}
ol li::before {
  content: counter(list) ".";
  width: 26px;
  color: #61B3E4;
  text-align: right;
  left: -32px;
  position: absolute;
}
ol li:last-child {
  margin-bottom: 0;
}
ol ol {
  list-style: lower-alpha;
}

ul.bare li {
  background: none;
  padding-left: 0;
  margin-bottom: 16px;
  position: relative;
}
ul.bare li::before {
  display: none;
}

ul.icon-list li {
  padding-left: 32px;
}
ul.icon-list li::before {
  display: none;
}
ul.icon-list .ico-phone {
  background: url("/images/icons/phone.svg") no-repeat left top;
}
ul.icon-list .ico-email-outline {
  background: url("/images/icons/email.svg") no-repeat left top;
}
ul.icon-list .ico-email {
  background: url("/images/icons/email.svg") no-repeat left top;
}
ul.icon-list .ico-home {
  background: url("/images/icons/home.svg") no-repeat left top;
}

@media screen and (min-width: 40em) {
  ul.split-list-2,
  ol.split-list-2 {
    margin-left: 0;
    padding-left: 0;
  }
  ul.split-list-2 li,
  ol.split-list-2 li {
    float: left;
    width: 50%;
    padding-right: 5%;
  }
  ul.split-list-2 li:nth-child(2n+1),
  ol.split-list-2 li:nth-child(2n+1) {
    clear: left;
  }
  ol.split-list-2 li {
    margin-right: 2%;
  }
  ul.split-list-3,
  ol.split-list-3 {
    padding-left: 0;
    margin-left: 0;
  }
  ul.split-list-3 li,
  ol.split-list-3 li {
    float: left;
    width: 33%;
    padding-right: 2%;
  }
  ol.split-list-3 li {
    margin-right: 2%;
  }
  ul.split-list-3 li:nth-child(3n+1),
  ol.split-list-3 li:nth-child(3n+1) {
    clear: left;
  }
}
/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
nav ul li {
  background: none;
  padding: 0;
  margin: 0;
}
nav ul li::before {
  content: unset;
  font-family: unset;
  font-size: unset;
  display: none;
}
nav ul li ul {
  margin: 0;
}
nav ul li ul li {
  background: none;
  margin: 0;
}
nav ul li ul li ul {
  display: none !important;
}
nav ul li ul li ul li {
  background: none;
}

/* @end */
/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/
blockquote {
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 1.1875rem;
  border-radius: 0;
  padding-left: 35px;
  padding-top: 10px;
  margin: 20px 0;
  position: relative;
}
blockquote::before {
  content: "";
  background: url("/images/icons/quote.svg") no-repeat left top;
  background-size: contain;
  width: 37px;
  height: 45px;
  color: #61B3E4;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}
blockquote cite {
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  color: #61B3E4;
  line-height: 1.1;
  display: inline-block;
  float: right;
}
blockquote cite .title {
  font-weight: 500;
  display: block;
}
blockquote cite::before {
  content: "";
}
blockquote .quote-wrap {
  padding: 0;
  margin: 0 0 10px;
}
blockquote .quote-wrap p {
  line-height: 1.25;
}

@media screen and (min-width: 40em) {
  blockquote {
    font-size: 1.4375rem;
    padding-top: 15px;
    padding-left: 45px;
  }
  blockquote::before {
    font-size: 28px;
  }
}
.simple-quote {
  font-size: 1.5rem;
  line-height: 1.17;
  border-left: solid 10px #61B3E4;
  padding: 0 0 0 16px;
}
.simple-quote::before {
  display: none;
}
.simple-quote.large {
  font-size: 1.5rem;
  color: #CDE8F9;
  line-height: 1.17;
}

@media screen and (min-width: 40em) {
  .simple-quote {
    font-size: 1.75rem;
    line-height: 1.29;
    padding-left: 24px;
  }
  .simple-quote.quote-right {
    max-width: 400px;
    margin-left: 32px;
    float: right;
  }
  .simple-quote.large {
    font-size: 2.4375rem;
    line-height: 1.08;
  }
  .simple-quote.small {
    font-size: 1.5rem;
    line-height: 1.29;
  }
}
/*----------------------------------------------------------*\
   Images

   Default image styling and various image helpers. The
   floating helpers will not float on smaller views. To
   enable an image floating on all views, use the -all
   suffixed classes.

\*----------------------------------------------------------*/
.page-content img {
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 15px;
}
.page-content img:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
}
.page-content img.img-border {
  border: 3px solid #CDE8F9;
  box-shadow: none;
}
.page-content img.img-border:hover {
  border-color: #61B3E4;
}

/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/
.img-left,
.img-right {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.img-left-all {
  float: left;
  margin-right: 20px;
}

.img-right-all {
  float: right;
  margin-left: 20px;
}

.img-full.img-center {
  margin: 0 auto 15px;
  display: block;
}

@media screen and (min-width: 40em) {
  .img-left {
    float: left;
    margin-right: 20px;
  }
  .img-right {
    float: right;
    margin-left: 20px;
  }
}
/* @end */
/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/
.button,
form input[type=submit] {
  background-color: #61B3E4;
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  color: #FFFFFF;
  padding: 15px 30px;
  margin-bottom: 1.25rem;
  border-radius: 100px;
  border: none;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.2;
}
.button:hover, .button:focus,
form input[type=submit]:hover,
form input[type=submit]:focus {
  background-color: #359edd;
  color: #FFFFFF;
  text-decoration: none;
}

.button.small {
  font-size: 1rem;
  padding: 10px 20px;
}

.button.large {
  font-size: 1.5rem;
  padding: 20px 40px;
}

.button.tiny {
  font-size: 0.875rem;
  padding: 5px 15px;
}

.button.hollow {
  background: none;
  color: #FFFFFF;
  border: solid 3px #FFFFFF;
}
.button.hollow:hover, .button.hollow:focus {
  background-color: #FFFFFF;
  color: #193769;
}
.button.hollow.brand {
  background: none;
  color: #193769;
  border-color: #193769;
}
.button.hollow.brand:hover, .button.hollow.brand:focus {
  background-color: #193769;
  color: #FFFFFF;
}

.button.ico .icon {
  margin-right: 7px;
}
.button.ico.large .icon {
  margin-right: 10px;
}

.button.green {
  background-color: #8CAB14;
}
.button.green:hover, .button.green:focus {
  background-color: #677d0f;
}

.button.orange {
  background-color: #F67F00;
}
.button.orange:hover, .button.orange:focus {
  background-color: #c36500;
}

.button.brand {
  background-color: #193769;
}
.button.brand:hover, .button.brand:focus {
  background-color: #0f2140;
}

.button.brand-alt-accent {
  background-color: #979DA8;
}
.button.brand-alt-accent:hover, .button.brand-alt-accent:focus {
  background-color: #7b8391;
}

.button.brand-alt {
  background-color: #151720;
}
.button.brand-alt:hover, .button.brand-alt:focus {
  background-color: #010101;
}

/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

   All sections are sized in 'em' which means they will
   resize their spacing according to the font-size of the
   parent container. Smaller font-size means less spacing
   and larger font-size increases the spacing.

\*----------------------------------------------------------*/
.content-section-full {
  padding: 48px 0;
}

.intro-section {
  padding-bottom: 2em;
}

.content-section {
  padding-bottom: 1.5em;
}

.content-sub-section {
  padding-bottom: 1em;
}

.texture-concrete {
  background: url("/images/cssimg/bg_concrete.jpg") repeat #FFFFFF;
  color: #414247;
}
.texture-concrete h2 {
  color: #193769;
}

.section-inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.section-inner::before, .section-inner::after {
  display: table;
  content: " ";
}
.section-inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .section-inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.section-inner:last-child:not(:first-child) {
  float: right;
}
.section-inner, .section-inner:last-child {
  float: none;
}

@media screen and (min-width: 40em) {
  .content-section-full {
    padding: 108px 0;
  }
  .intro-section {
    padding-bottom: 0 0 3.5em;
  }
  .content-section {
    padding-bottom: 2.5em;
  }
  .content-sub-section {
    padding-bottom: 1.5em;
  }
}
/*----------------------------------------------------------*\
   Icon

   Default icon styling and sprite icon styles.

\*----------------------------------------------------------*/
/*------------------------------------*\
    @group Default Icon Style
\*------------------------------------*/
.icons {
  width: 1em;
  height: 1em;
  fill: currentColor;
  vertical-align: middle;
  display: inline-block;
  position: relative;
  top: -0.1em;
}

/* @end Default Icon Style */
/*------------------------------------*\
    @group Bitmap Sprites
\*------------------------------------*/
.ico-github {
  background-image: url(production/images/sprites.png);
  background-position: -149px 0px;
  width: 142px;
  height: 120px;
}

@media print, screen and (min-width: 40em) {
  .ico-github {
    background-image: url(production/images/sprites.png);
    background-position: -149px 0px;
    width: 142px;
    height: 120px;
  }
}
@media print and (-webkit-min-device-pixel-ratio: 2), print and (min-resolution: 192dpi), screen and (min-width: 40em) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: 40em) and (min-resolution: 192dpi) {
  .ico-github {
    background-image: url(images/sprites@2x.png);
    background-size: 291px 240px;
  }
}
/* @end */
/*----------------------------------------------------------*\
   Tables

	The majority of the table styling comes from the
    Foundation settings file.

\*----------------------------------------------------------*/
table {
  margin-bottom: 2rem;
}
table th {
  font-size: 1.125em;
  line-height: 1.2;
  border: solid 1px #FFFFFF;
}
table td {
  border: solid 1px #FFFFFF;
}

/*----------------------------------------------------------*\
    Helpers

    This file contains most of the available helper
    utilities (not including base Foundation). Use these
    when small tweaks are needed on existing pieces that
    don't warrant a full semantic CSS process.

\*----------------------------------------------------------*/
/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/
.text-xlrg {
  font-size: 1.4375em !important;
}

.text-lrg {
  font-size: 1.25em !important;
}

.text-sml {
  font-size: 0.75em !important;
}

.text-shadow {
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5) !important;
}

.text-shadow-light {
  text-shadow: 0 2px 2px rgba(255, 255, 255, 0.53) !important;
}

@media screen and (min-width: 40em) {
  .text-xlrg {
    font-size: 1.62em !important;
  }
  .text-lrg {
    font-size: 1.27em !important;
  }
  .text-sml {
    font-size: 0.75em !important;
  }
}
/* @end */
/*------------------------------------*\
    @group Text Colors
\*------------------------------------*/
.highlight {
  color: #193769;
}

.highlight-alt {
  color: #151720;
}

.text-brand {
  color: #193769 !important;
}

.text-brand-accent {
  color: #61B3E4 !important;
}

.text-brand-alt {
  color: #151720 !important;
}

.text-brand-alt-accent {
  color: #979DA8 !important;
}

.text-green {
  color: #8CAB14 !important;
}

.text-orange {
  color: #F67F00 !important;
}

.text-dark-blue {
  color: #222732 !important;
}

.text-light-blue {
  color: #CDE8F9 !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-off-white {
  color: #E8EBF1 !important;
}

.text-light-gray {
  color: #C4CDCF !important;
}

.text-gray {
  color: #414247 !important;
}

.text-dark-gray {
  color: #1C1819 !important;
}

.text-black {
  color: #000000 !important;
}

/* @end */
/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/
.bg-brand {
  background-color: #193769 !important;
}

.bg-brand-accent {
  background-color: #61B3E4 !important;
}

.bg-brand-alt {
  background-color: #151720 !important;
}

.bg-brand-alt-accent {
  background-color: #979DA8 !important;
}

.bg-green {
  background-color: #8CAB14 !important;
}

.bg-orange {
  background-color: #F67F00 !important;
}

.bg-dark-blue {
  background-color: #222732 !important;
}

.bg-light-blue {
  background-color: #CDE8F9 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.bg-off-white {
  background-color: #E8EBF1 !important;
}

.bg-light-gray {
  background-color: #C4CDCF !important;
}

.bg-gray {
  background-color: #414247 !important;
}

.bg-dark-gray {
  background-color: #1C1819 !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-gradient-white {
  background: linear-gradient(180deg, rgb(231, 231, 231) 0%, rgb(255, 255, 255) 100%) !important;
}

.box-shadow {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.box-shadow-light {
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.2);
}

.bg-round {
  border-radius: 1000px;
}

/* @end */
/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.stacked {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

/* @end */
/*------------------------------------*\
    @group Responsive Map
\*------------------------------------*/
.map-embed {
  height: 0;
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}

.map-embed iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

/* @end Responsive Map */
/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/
.bg-twitter {
  background-color: #00aced !important;
}

.bg-facebook {
  background-color: #3b5998 !important;
}

.bg-googleplus {
  background-color: #dd4b39 !important;
}

.bg-pinterest {
  background-color: #cb2027 !important;
}

.bg-linkedin {
  background-color: #007bb6 !important;
}

.bg-youtube {
  background-color: #bb0000 !important;
}

.bg-vimeo {
  background-color: #aad450 !important;
}

.bg-instagram {
  background-color: #517fa4 !important;
}

.bg-quora {
  background-color: #a82400 !important;
}

.bg-foursquare {
  background-color: #0072b1 !important;
}

.bg-stumbleupon {
  background-color: #EB4823 !important;
}

.bg-soundcloud {
  background-color: #ff3a00 !important;
}

.text-twitter {
  color: #00aced !important;
}

.text-facebook {
  color: #3b5998 !important;
}

.text-googleplus {
  color: #dd4b39 !important;
}

.text-pinterest {
  color: #cb2027 !important;
}

.text-linkedin {
  color: #007bb6 !important;
}

.text-youtube {
  color: #bb0000 !important;
}

.text-vimeo {
  color: #aad450 !important;
}

.text-instagram {
  color: #517fa4 !important;
}

.text-quora {
  color: #a82400 !important;
}

.text-foursquare {
  color: #0072b1 !important;
}

.text-stumbleupon {
  color: #EB4823 !important;
}

.text-soundcloud {
  color: #ff3a00 !important;
}

/* @end */
.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transition-duration: 0.3s;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/
.page-header {
  background: #222732;
  width: 100%;
  height: 72px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.page-header .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap;
}
.page-header .logo {
  padding-top: 12px;
  padding-bottom: 12px;
  flex: 0 0 auto;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .page-header .logo {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.page-header .mobile-menu-toggle {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: 0;
  align-self: center;
}
@media print, screen and (min-width: 40em) {
  .page-header .mobile-menu-toggle {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.page-header .mobile-nav {
  background: #222732;
  width: 100%;
  padding: 18px 18px 48px;
  display: none;
}
.page-header .mobile-menu-toggle {
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: right;
  position: relative;
}
.page-header .mobile-menu-toggle a {
  color: #FFFFFF;
}
.page-header .mobile-menu-toggle a:hover, .page-header .mobile-menu-toggle a:focus {
  color: #61B3E4;
  text-decoration: none;
}
.page-header .mobile-menu-toggle .nav-label .close-text {
  font-size: 32px;
}
.page-header .mobile-menu-toggle .nav-label .close-text, .page-header .mobile-menu-toggle .nav-label.open .open-text {
  display: none;
}
.page-header .mobile-menu-toggle .nav-label.open .close-text {
  display: block;
}
.page-header .mobile-menu-anchor {
  padding: 0;
}

.nav-slide-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transition-duration: 0.2s;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.nav-slide-in.mui-enter.mui-enter-active {
  transform: translateX(0);
}

.nav-slide-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden;
}

.nav-slide-out.mui-leave.mui-leave-active {
  transform: translateX(100%);
}

@media screen and (max-width: 640px) {
  .large-nav {
    display: none;
  }
}
@media screen and (min-width: 40em) {
  .page-header {
    height: 92px;
  }
  .page-header .logo {
    padding-bottom: 18px;
    padding-top: 18px;
  }
  .page-header .logo img {
    max-height: 56px;
  }
  .page-header .large-nav {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    flex: 1 1 0px;
    padding-right: 0.625rem;
    padding-left: 0.625rem;
    min-width: 0;
  }
}
@media screen and (min-width: 40em) and (min-width: 40em) {
  .page-header .large-nav {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
@media screen and (min-width: 40em) {
  .page-header .mobile-menu-toggle,
  .page-header .mobile-nav {
    display: none !important;
  }
}
/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.main-nav {
  display: block;
}
.main-nav ul {
  height: 100%;
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 2rem;
  text-align: center;
  line-height: 1;
  overflow: hidden;
}
.main-nav ul li {
  margin-bottom: 42px;
  line-height: inherit;
}
.main-nav ul a {
  color: #61B3E4;
  line-height: inherit;
}
.main-nav ul a:hover, .main-nav ul a:focus, .main-nav ul a.selected {
  color: #FFFFFF;
}

@media screen and (min-width: 40em) {
  .main-nav {
    height: 92px;
  }
  .main-nav ul {
    font-size: 1.5rem;
    text-align: right;
    display: flex;
    justify-content: flex-end;
  }
  .main-nav ul li {
    line-height: inherit;
    margin-left: 28px;
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
  .main-nav ul li:first-child {
    margin-left: 0;
  }
  .main-nav ul a::before {
    content: "";
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    border-top: solid 5px #979DA8;
    transition: border 0.2s;
    opacity: 0.5;
  }
  .main-nav ul a.selected {
    color: #FFFFFF;
  }
  .main-nav ul a.selected::before {
    transition: none;
  }
  .main-nav ul a:hover::before, .main-nav ul a.selected::before {
    border-top: solid 20px #FFFFFF;
    opacity: 1;
  }
}
/* @end Navigation */
/*------------------------------------*\
    @group Header with background images
\*------------------------------------*/
.bg-template .page-header {
  background: none;
  transition: background 0.3s;
}
.bg-template .page-header.open {
  background: #222732;
}

@media screen and (min-width: 40em) {
  .bg-template .page-header {
    height: 130px;
    transition: background-color 0.3s;
  }
  .bg-template .page-header.open {
    background-color: #222732;
  }
  .bg-template .main-nav {
    height: 130px;
  }
}
/* @end Header with background images */
/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
  margin-top: 143px;
}
.hero .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.hero .inner::before, .hero .inner::after {
  display: table;
  content: " ";
}
.hero .inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .hero .inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.hero .inner:last-child:not(:first-child) {
  float: right;
}
.hero .inner, .hero .inner:last-child {
  float: none;
}
.hero .highlight {
  color: #61B3E4;
}
.hero p {
  font-size: 1.1875rem;
  line-height: 1.52;
}

@media screen and (min-width: 40em) {
  .hero {
    margin-top: 136px;
  }
  .hero p {
    font-size: 1.75rem;
  }
}
.simple-hero {
  margin-bottom: 16px;
}
@media screen and (min-width: 40em) {
  .simple-hero {
    margin-bottom: 76px;
  }
}

.close-hero {
  margin-bottom: 8px;
}
@media screen and (min-width: 40em) {
  .close-hero {
    margin-bottom: 24px;
  }
}

/* @end Hero */
/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/
.page-content {
  padding: 32px 0 32px;
  margin-top: 100px;
}
.page-content .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.page-content .inner::before, .page-content .inner::after {
  display: table;
  content: " ";
}
.page-content .inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .page-content .inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.page-content .inner:last-child:not(:first-child) {
  float: right;
}
.page-content .inner, .page-content .inner:last-child {
  float: none;
}

/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/
.page-footer {
  font-size: 0.875rem;
}
.page-footer h2 {
  font-size: 1.25rem;
  margin-bottom: 12px;
}
.page-footer .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.page-footer .inner::before, .page-footer .inner::after {
  display: table;
  content: " ";
}
.page-footer .inner::after {
  clear: both;
}
.page-footer .inner > div {
  padding-top: 28px;
  padding-bottom: 8px;
}
.page-footer .first-section {
  background: #293443;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .page-footer .first-section {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.page-footer .first-section:last-child:not(:first-child) {
  float: right;
}
.page-footer .last-section {
  background: #222732;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .page-footer .last-section {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.page-footer .last-section:last-child:not(:first-child) {
  float: right;
}
.page-footer .logo {
  margin-bottom: 18px;
}
.page-footer .copyright {
  font-size: 0.75rem;
  text-align: center;
}
.page-footer .footer-nav ul {
  margin-top: 0;
}
.page-footer .footer-nav li {
  width: 50%;
  margin: 0 0 4px;
  padding-left: 0;
  padding-right: 10px;
  display: inline-block;
}
.page-footer .footer-nav li::before {
  display: none;
}
.page-footer .footer-nav a {
  color: #979DA8;
}
.page-footer .footer-nav a:hover, .page-footer .footer-nav a:focus {
  color: #FFFFFF;
}
.page-footer .footer-image-list ul {
  margin-top: 0;
}
.page-footer .footer-image-list ul li {
  background: none;
}
.page-footer .footer-image-list li {
  padding-left: 0;
  margin: 0 24px 12px 0;
  display: inline-block;
}
.page-footer .footer-image-list li::before {
  display: none;
}
.page-footer .footer-image-list li::last-child {
  margin-right: 0;
}
.page-footer .heading h2 {
  display: flex;
  align-items: center;
}
.page-footer .heading h2::after {
  content: "";
  background: #2b313d;
  width: 100%;
  height: 2px;
}
.page-footer .heading h2 span {
  padding-right: 10px;
  flex-shrink: 0;
}

@media screen and (min-width: 40em) {
  .page-footer {
    background: linear-gradient(to right, #293443 0%, #293443 50%, #222732 50%, #222732 100%);
  }
  .page-footer .first-section {
    width: 50%;
    background: none;
  }
  .page-footer .last-section {
    width: 50%;
    background: none;
  }
  .page-footer .copyright {
    text-align: left;
  }
  .page-footer .copyright br {
    display: none;
  }
}
@media screen and (min-width: 56.25em) {
  .page-footer .inner > div {
    padding-top: 48px;
    padding-bottom: 28px;
  }
  .page-footer .first-section {
    padding-right: 76px;
  }
  .page-footer .last-section {
    padding-left: 38px;
  }
  .page-footer .footer-nav {
    width: 50%;
    padding-right: 32px;
    float: left;
  }
  .page-footer .footer-nav li {
    width: 100%;
    padding-left: 0;
    display: block;
  }
  .page-footer .footer-nav li::before {
    display: none;
  }
  .page-footer .footer-nav li::last-child {
    margin-right: 0;
  }
  .page-footer .footer-image-list {
    width: 50%;
    float: left;
  }
  .page-footer .copyright {
    float: left;
  }
}
.double-column-section .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.double-column-section .inner::before, .double-column-section .inner::after {
  display: table;
  content: " ";
}
.double-column-section .inner::after {
  clear: both;
}
.double-column-section .first-col {
  background-color: #151720;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-top: 32px;
  padding-bottom: 48px;
}
@media print, screen and (min-width: 40em) {
  .double-column-section .first-col {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.double-column-section .first-col:last-child:not(:first-child) {
  float: right;
}
.double-column-section .last-col {
  background-color: #1C1819;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-top: 32px;
  padding-bottom: 48px;
}
@media print, screen and (min-width: 40em) {
  .double-column-section .last-col {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.double-column-section .last-col:last-child:not(:first-child) {
  float: right;
}

@media screen and (min-width: 40em) {
  .double-column-section {
    background: linear-gradient(to right, #151720 0%, #151720 50%, #1C1819 50%, #1C1819 100%);
    padding: 82px 0;
  }
  .double-column-section .first-col {
    background-color: transparent;
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .double-column-section .last-col {
    background-color: transparent;
    width: 50%;
    padding-top: 0;
    padding-bottom: 0;
  }
}
@media screen and (min-width: 64em) {
  .double-column-section .first-col {
    padding-right: 64px;
  }
  .double-column-section .last-col {
    padding-left: 64px;
  }
}
/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/
.callout {
  border-radius: 4px;
  overflow: hidden;
}
.callout .heading {
  background-color: #61B3E4;
  font-size: 1.75rem;
  color: #FFFFFF;
  padding: 22px 19px;
  margin: 0;
}
.callout .content {
  background-color: #FFFFFF;
  color: #414247;
  padding: 25px 30px 1px;
}
.callout p {
  font-size: 1rem;
  margin: 0 0 1em;
}
.callout ul {
  margin-top: 0;
}

.callout-object .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.callout-object .inner::before, .callout-object .inner::after {
  display: table;
  content: " ";
}
.callout-object .inner::after {
  clear: both;
}
.callout-object .section-heading {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .callout-object .section-heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.callout-object .section-heading:last-child:not(:first-child) {
  float: right;
}
.callout-object .content-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .callout-object .content-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.callout-object .content-container:last-child:not(:first-child) {
  float: right;
}
.callout-object .callout-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .callout-object .callout-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.callout-object .callout-container:last-child:not(:first-child) {
  float: right;
}

@media screen and (min-width: 40em) {
  .callout .heading {
    font-size: 2.1875rem;
    padding: 20px 30px;
  }
  .callout-object .content-container {
    width: 50%;
  }
  .callout-object .callout-container {
    width: 50%;
  }
}
@media screen and (min-width: 64em) {
  .callout-object .content-container {
    width: 58.3333333333%;
  }
  .callout-object .callout-container {
    width: 41.6666666667%;
  }
}
/*------------------------------------*\
    @group Icon Callout
\*------------------------------------*/
.icon-callout .header {
  background: #E8EBF1;
  padding: 20px 15px;
  border-radius: 4px 4px 0 0;
  position: relative;
}
.icon-callout .header .icon {
  background: #193769;
  width: 96px;
  height: 96px;
  font-size: 24px;
  padding: 10px;
  border-radius: 50%;
  line-height: 1;
  position: absolute;
  top: -20px;
  right: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.icon-callout .header .icon.icon-paper-plane {
  padding: 11px 13px 13px 11px;
}
.icon-callout .header .icon.icon-case-study {
  padding: 12px;
}
.icon-callout .header .icon.icon-trash {
  padding: 11px 14px 13px 15px;
}
.icon-callout .header .icon.icon-users {
  padding: 11px 10px 13px 12px;
}
.icon-callout .heading {
  font-size: 2.25rem;
  color: #193769;
  margin-bottom: 0;
}
.icon-callout .content {
  background-color: #FFFFFF;
  font-size: 1.1875rem;
  color: #414247;
  padding: 20px 20px 0;
  border-radius: 0 0 4px 4px;
}

@media screen and (min-width: 40em) {
  .icon-callout .header .icon {
    font-size: 48px;
    top: -39px;
    right: 24px;
  }
  .icon-callout .header .icon.icon-paper-plane {
    padding: 25px 29px 23px 19px;
  }
  .icon-callout .header .icon.icon-case-study {
    padding: 23px;
  }
  .icon-callout .header .icon.icon-trash {
    padding: 26px 29px 22px;
  }
  .icon-callout .header .icon.icon-users {
    padding: 26px 22px 22px;
  }
  .icon-callout .heading {
    font-size: 2.75rem;
  }
  .icon-callout .content {
    font-size: 1.4375rem;
    display: flex;
    align-items: center;
  }
  .icon-callout .content .text {
    padding-right: 60px;
  }
  .icon-callout .content .action {
    flex-shrink: 0;
  }
  .icon-callout.single .content {
    display: block;
  }
}
/* @end Icon Callout */
/*------------------------------------*\
    @group Callout Section Band
\*------------------------------------*/
.callout-band {
  background-color: #61B3E4;
  margin-top: 50px;
}
.callout-band .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.callout-band .inner::before, .callout-band .inner::after {
  display: table;
  content: " ";
}
.callout-band .inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .callout-band .inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.callout-band .inner:last-child:not(:first-child) {
  float: right;
}
.callout-band .inner, .callout-band .inner:last-child {
  float: none;
}
.callout-band .icon-callout {
  margin-top: -50px;
}
.callout-band .icon-callout .content {
  border-radius: 0;
}

@media screen and (min-width: 40em) {
  .callout-band .icon-callout {
    max-width: 625px;
    margin-left: auto;
    margin-right: auto;
  }
  .callout-band .icon-callout.wide {
    max-width: 810px;
  }
}
/* @end Callout Section Band */
/*------------------------------------*\
    @group Callout COntainer
\*------------------------------------*/
.callouts {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 69px;
  margin-bottom: 69px;
}
.callouts::before, .callouts::after {
  display: table;
  content: " ";
}
.callouts::after {
  clear: both;
}
.callouts .callout-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-bottom: 48px;
}
@media print, screen and (min-width: 40em) {
  .callouts .callout-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.callouts .callout-container:last-child:not(:first-child) {
  float: right;
}

@media screen and (min-width: 40em) {
  .callouts .callout-container {
    width: 50%;
    margin-bottom: 0;
  }
}
/* @end Callout COntainer */
/*------------------------------------*\
    @group Animated Hamburger icon
\*------------------------------------*/
.hamburger {
  width: 20px;
  height: 24px;
  font-size: 0;
  margin: 0;
  padding: 0;
  transition: background 0.3s;
  cursor: pointer;
  display: block;
  position: relative;
  overflow: hidden;
}
.hamburger:focus {
  outline: none;
}
.hamburger span {
  height: 3px;
  background: #387174;
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}
.hamburger span::before, .hamburger span::after {
  content: "";
  background-color: #387174;
  width: 100%;
  height: 3px;
  display: block;
  position: absolute;
  left: 0;
}
.hamburger span::before {
  top: -6px;
}
.hamburger span::after {
  bottom: -6px;
}

.hamburger-x span {
  transition: background 0s 0.3s;
}
.hamburger-x span::before, .hamburger-x span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}
.hamburger-x span::before {
  transition-property: top, transform;
}
.hamburger-x span::after {
  transition-property: bottom, transform;
}

.hamburger-x.active span {
  background: none;
}
.hamburger-x.active span::before {
  top: 0;
  transform: rotate(45deg);
}
.hamburger-x.active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}
.hamburger-x.active span::before, .hamburger-x.active span::after {
  background-color: #193769;
  transition-delay: 0s, 0.3s;
}

/* @end Animated Hamburger icon */
/*------------------------------------*\
    @group Media Object with bg image
\*------------------------------------*/
.bg-media-object {
  background: url("/images/services/bg_asbestos_cleanup_crew.jpg") no-repeat right bottom;
  padding: 72px 0;
}
.bg-media-object .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.bg-media-object .inner::before, .bg-media-object .inner::after {
  display: table;
  content: " ";
}
.bg-media-object .inner::after {
  clear: both;
}
.bg-media-object .text {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .bg-media-object .text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.bg-media-object .text:last-child:not(:first-child) {
  float: right;
}
.bg-media-object h2 {
  font-size: 2.0625rem;
  margin-bottom: 13px;
  display: flex;
}
.bg-media-object h2 .icon {
  color: #61B3E4;
  margin-right: 12px;
}
.bg-media-object.bg-lead-paint {
  background-image: url("/images/services/bg_lead_paint.jpg");
  background-position: left top;
}
.bg-media-object.bg-paint-cans {
  background-image: url("/images/services/bg_hazardous_waste.jpg");
  background-position: right bottom;
}

@media screen and (min-width: 40em) {
  .bg-media-object {
    background-image: url("/images/services/bg_asbestos_cleanup_crew_lrg.jpg");
    background-position: center center;
    padding: 111px 0;
  }
  .bg-media-object h2 {
    font-size: 2.4375rem;
  }
  .bg-media-object.bg-lead-paint {
    background-image: url("/images/services/bg_lead_paint_lrg.jpg");
    background-position: center center;
  }
  .bg-media-object.bg-paint-cans {
    background-image: url("/images/services/bg_hazardous_waste_lrg.jpg");
    background-position: center center;
  }
}
@media screen and (min-width: 48em) {
  .bg-media-object .text {
    width: 58.3333333333%;
  }
  .bg-media-object.flip .text {
    float: right;
  }
  .bg-media-object.wide .text {
    width: 66.6666666667%;
  }
}
/* @end Media Object with bg image */
/*------------------------------------*\
    @group Media Object
\*------------------------------------*/
.media-object .text {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .media-object .text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.media-object .text:last-child:not(:first-child) {
  float: right;
}
.media-object .media {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .media-object .media {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.media-object .media:last-child:not(:first-child) {
  float: right;
}
.media-object .media-list {
  text-align: center;
}

@media screen and (min-width: 40em) {
  .media-object .text {
    width: 50%;
  }
  .media-object .text > *:last-child {
    margin-bottom: 0;
  }
  .media-object .media {
    width: 50%;
  }
}
/* @end Media Object */
/*------------------------------------*\
    @group Bio Cards
\*------------------------------------*/
.bio-cards {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .bio-cards {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .bio-cards {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.bio-cards::before, .bio-cards::after {
  display: table;
  content: " ";
}
.bio-cards::after {
  clear: both;
}
.bio-cards .heading {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .bio-cards .heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.bio-cards .heading:last-child:not(:first-child) {
  float: right;
}
.bio-cards .card-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .bio-cards .card-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.bio-cards .card-container:last-child:not(:first-child) {
  float: right;
}
.bio-cards .card {
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 32px;
}
.bio-cards .card .info {
  background: #193769;
  color: #CDE8F9;
  padding: 12px;
  border-top: solid 2px #61B3E4;
  border-radius: 0 0 4px 4px;
  transition: background 0.3s, border-color 0.3s;
}
.bio-cards .card .headshot {
  background: #FFFFFF;
  padding-top: 12px;
  border-radius: 4px 4px 0 0;
  position: relative;
}
.bio-cards .card .headshot img {
  position: relative;
  z-index: 10;
}
.bio-cards .card h3 {
  font-size: 1.75rem;
  color: #FFFFFF;
  margin: 0;
}
.bio-cards .card p {
  font-size: 1rem;
  margin: 0;
}
.bio-cards .card:hover .info {
  background: #61B3E4;
  border-color: brand;
}

@media screen and (min-width: 40em) {
  .bio-cards .heading,
  .bio-cards .card-container {
    width: 33.3333333333%;
  }
  .bio-cards .heading:last-child:last-child,
  .bio-cards .card-container:last-child:last-child {
    float: left;
  }
  .bio-cards > div:nth-child(4n) {
    clear: left;
  }
  .bio-cards .offset-col-1 {
    margin-left: 33.3333333333%;
  }
  .bio-cards .card {
    margin-bottom: 56px;
  }
}
@media screen and (min-width: 64em) {
  .bio-cards .heading,
  .bio-cards .card-container {
    width: 25%;
  }
  .bio-cards .heading.offset-left,
  .bio-cards .card-container.offset-left {
    margin-left: 12.5%;
  }
  .bio-cards .offset-col-1 {
    margin-left: 25%;
  }
}
/* @end Bio Cards */
/*------------------------------------*\
    @group Tile Cards
\*------------------------------------*/
.tiles {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .tiles {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .tiles {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.tiles::before, .tiles::after {
  display: table;
  content: " ";
}
.tiles::after {
  clear: both;
}

.tile-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-bottom: 32px;
}
@media print, screen and (min-width: 40em) {
  .tile-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.tile-container:last-child:not(:first-child) {
  float: right;
}
.tile-container > a {
  display: block;
}

.tile-image {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #FFFFFF;
  position: relative;
}
.tile-image .title {
  position: absolute;
  bottom: 24px;
  left: 32px;
  z-index: 10;
}
.tile-image .title h2 {
  margin: 0;
}
.tile-image .title p {
  font-family: "Korolev W01", Impact, sans-serif;
  margin: 0;
}
.tile-image::after {
  content: "";
  background: #193769;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.4s;
}

a .tile-image:hover::after,
a .tile-image:focus::after {
  opacity: 0.78;
  visibility: visible;
}

@media screen and (min-width: 31.25em) {
  .tile-container {
    width: 50%;
  }
}
@media screen and (min-width: 50em) {
  .tile-container {
    width: 33.3333333333%;
  }
}
.bg-nitrogen-plant {
  background-image: url("/images/tiles/case-studies/nitrogen_plant.jpg");
}

.bg-hydro-power-plant {
  background-image: url("/images/tiles/case-studies/hydro_power_plant.jpg");
}

.bg-coal-power-plant {
  background-image: url("/images/tiles/case-studies/coal_fired_power_plant.jpg");
}

.bg-pumping-station {
  background-image: url("/images/tiles/case-studies/pumping_station.jpg");
}

.bg-manufacturing {
  background-image: url("/images/tiles/case-studies/manufacturing.jpg");
}

.bg-gas-plant {
  background-image: url("/images/tiles/case-studies/gas_plant.jpg");
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 13/10), only screen and (min-resolution: 120dpi) {
  .bg-nitrogen-plant {
    background-image: url("/images/tiles/case-studies/nitrogen_plant@2x.jpg");
  }
  .bg-hydro-power-plant {
    background-image: url("/images/tiles/case-studies/hydro_power_plant@2x.jpg");
  }
  .bg-coal-power-plant {
    background-image: url("/images/tiles/case-studies/coal_fired_power_plant@2x.jpg");
  }
  .bg-pumping-station {
    background-image: url("/images/tiles/case-studies/pumping_station@2x.jpg");
  }
  .bg-manufacturing {
    background-image: url("/images/tiles/case-studies/manufacturing@2x.jpg");
  }
  .bg-gas-plant {
    background-image: url("/images/tiles/case-studies/gas_plant@2x.jpg");
  }
}
/* @end Tile Cards */
.alert-bar {
  background-color: #193769;
  padding: 20px 10px;
  position: relative;
  z-index: 51;
  display: flex;
  justify-content: center;
  align-items: center;
}
.alert-bar .heading {
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: #FFFFFF;
  margin-bottom: 0;
  text-align: center;
}

/*----------------------------------------------------------*\
    Forms

    The majority of the form styling comes from the
    Foundation settings file. Any new forms added that
    need specific styling (like search forms as seen here)
    should be added in this file.

\*----------------------------------------------------------*/
form .req {
  color: #979DA8;
}

form label {
  text-transform: uppercase;
  margin-bottom: 5px;
}
form select {
  margin-top: 5px;
}
form [type=checkbox] + label, form [type=radio] + label {
  vertical-align: 2px;
  margin-left: 5px;
}
form textarea {
  min-height: 150px;
}
form [type=color],
form [type=date],
form [type=datetime-local],
form [type=datetime],
form [type=email],
form [type=month],
form [type=number],
form [type=password],
form [type=search],
form [type=tel],
form [type=text],
form [type=time],
form [type=url],
form [type=week],
form textarea {
  margin-bottom: 1.25rem;
}

.form-contained {
  background: #414247;
  padding: 1.5rem 1rem 0;
}

/*----------------------------------------------------------*\
    Slider

    All styles for sliders uses throughout the site are in
    this file.

\*----------------------------------------------------------*/
/*------------------------------------*\
    @group Hero Slider
\*------------------------------------*/
.hero .slider {
  background: #1C1819;
  min-height: 500px;
  width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  overflow-x: hidden;
}

.hero .slider .slide-content {
  width: 100%;
  min-height: 500px;
  text-align: center;
  padding: 80px 65px 40px;
  margin: 0 auto;
  z-index: 50;
}
.hero .slider .slide-content .text {
  margin: 0 0 25px;
}
.hero .slider .slide-content h2 {
  font-size: 2.25rem;
  font-weight: 300;
  color: #FFFFFF;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.05;
  margin: 0 0 20px;
}
.hero .slider .slide-content h3 {
  font-family: "Neue Helvetica W01", Helvetica, "Trebuchet MS", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: normal;
  color: #FFFFFF;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
  line-height: 1.5;
  margin: 0 0 20px;
}
.hero .slider .slide-content .button {
  background: #193769;
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #FFFFFF;
  line-height: 1.2;
  padding: 16px 39px;
}
.hero .slider .slide-content .button:hover {
  background: #000000;
  text-decoration: none;
}

.hero .slider .slick-arrow {
  width: 40px;
  height: 40px;
  background: rgba(25, 55, 105, 0.8);
  font-family: "PtiIndustrial";
  color: #193769;
  padding: 7px 14px 7px 10px;
  margin: 0;
  display: block;
  position: absolute;
  top: auto;
  bottom: 0;
  transform: translate(0, 0);
  z-index: 10;
  overflow: hidden;
  opacity: 1;
  transition: all 0.3s;
}
.hero .slider .slick-arrow:hover {
  background-color: #000000;
}
.hero .slider .slick-arrow::before, .hero .slider .slick-arrow::after {
  font-size: 28px;
  opacity: 1;
}
.hero .slider .slick-arrow.slick-prev {
  left: 0;
}
.hero .slider .slick-arrow.slick-prev::before {
  content: "\f009";
}
.hero .slider .slick-arrow.slick-next {
  padding: 7px 10px 7px 14px;
  right: 0;
}
.hero .slider .slick-arrow.slick-next::before {
  content: "\f008";
}

/* Slide backgrounds */
.hero .slider .slide {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
}
.hero .slider .slide.bg-desk {
  background-image: url("/images/slides/bg_desk.jpg");
}

@media screen and (min-width: 40em) {
  .hero .slider {
    height: 500px;
  }
  .hero .slider .slide {
    height: 500px;
  }
  .hero .slider .slide-content {
    max-width: 960px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .hero .slider .slide-content .text > * {
    user-select: text;
  }
  .hero .slider .slide-content h2 {
    font-size: 2.75rem;
    display: inline-block;
  }
  .hero .slider .slide-content h3 {
    font-size: 2rem;
    display: inline-block;
  }
}
@media only screen and (min-width: 46.9375em) {
  .hero .slider .slide.bg-desk {
    background: url("/images/slides/large/bg_desk.jpg") no-repeat center top;
  }
}
@media only screen and (min-width: 65.125em) {
  .hero .slider .slick-arrow {
    top: 50%;
    transform: translate(0, -50%);
  }
}
/* @end */
.home-template {
  background-image: url("/images/hero/bg_refinery.jpg");
}
.home-template .callouts {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  margin-top: 69px;
  margin-bottom: 69px;
}
.home-template .callouts::before, .home-template .callouts::after {
  display: table;
  content: " ";
}
.home-template .callouts::after {
  clear: both;
}
.home-template .callouts .callout-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-bottom: 48px;
}
@media print, screen and (min-width: 40em) {
  .home-template .callouts .callout-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.home-template .callouts .callout-container:last-child:not(:first-child) {
  float: right;
}
.home-template .map-object {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  margin-bottom: 48px;
}
.home-template .map-object .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.home-template .map-object .inner::before, .home-template .map-object .inner::after {
  display: table;
  content: " ";
}
.home-template .map-object .inner::after {
  clear: both;
}
.home-template .map-object h2 {
  color: #61B3E4;
}
.home-template .map-object .text {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .home-template .map-object .text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.home-template .map-object .text:last-child:not(:first-child) {
  float: right;
}
.home-template .map-object .text p {
  font-size: 1.1875rem;
}
.home-template .map-object .map {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  margin-bottom: 24px;
}
@media print, screen and (min-width: 40em) {
  .home-template .map-object .map {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.home-template .map-object .map:last-child:not(:first-child) {
  float: right;
}
.home-template .map-object .map img {
  margin-bottom: 1rem;
}
.home-template .map-object .disclaimer {
  font-size: 0.875rem;
  color: #979DA8;
}
.home-template .safety-certifications .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
}
.home-template .safety-certifications .inner::before, .home-template .safety-certifications .inner::after {
  display: table;
  content: " ";
}
.home-template .safety-certifications .inner::after {
  clear: both;
}
.home-template .safety-certifications .media ul {
  margin: 0;
}
.home-template .safety-certifications .media ul li {
  background: none;
}
.home-template .safety-certifications .media li {
  margin: 16px 32px 16px 0;
  padding-left: 0;
  display: inline-block;
}
.home-template .safety-certifications .media li::before {
  display: none;
}
.home-template .safety-certifications .media li:last-child {
  margin-right: 0;
}

@media screen and (min-width: 40em) {
  .home-template {
    background-image: url("/images/hero/bg_refinery_med.jpg");
  }
  .home-template .callouts .callout-container {
    width: 50%;
    margin-bottom: 0;
  }
}
@media screen and (min-width: 50em) {
  .home-template .map-object {
    max-width: 100%;
    margin-top: 140px;
    margin-bottom: 108px;
  }
  .home-template .map-object .text {
    width: 33.3333333333%;
    position: relative;
    left: -66.6666666667%;
  }
  .home-template .map-object .text p {
    font-size: 1.125rem;
  }
  .home-template .map-object .map {
    width: 66.6666666667%;
    position: relative;
    left: 33.3333333333%;
    margin-bottom: 0;
  }
  .home-template .hero {
    margin: 100px 0;
  }
  .home-template .hero p {
    font-size: 1.125rem;
  }
}
@media screen and (min-width: 64em) {
  .home-template {
    background-image: url("/images/hero/bg_refinery_lrg.jpg");
  }
  .home-template .safety-certifications .media ul {
    margin-top: 24px;
  }
}
.services-template {
  background-image: url("/images/hero/bg_asbestos_containment_crew.jpg");
}

@media screen and (min-width: 40em) {
  .services-template {
    background-image: url("/images/hero/bg_asbestos_containment_crew_med.jpg");
  }
}
@media screen and (min-width: 64em) {
  .services-template {
    background-image: url("/images/hero/bg_asbestos_containment_crew_lrg.jpg");
  }
}
.about-template {
  background-image: url("/images/hero/bg_boom_crew_inspecting_chimney.jpg");
}
.about-template .team-section {
  padding-bottom: 16px;
}
.about-template .team-bio h1 {
  color: #61B3E4;
  margin: 0;
}
.about-template .team-bio .bio-name h2 {
  font-size: 1.4375rem;
}
.about-template .team-bio .team-details {
  overflow: hidden;
  margin-bottom: 24px;
}
.about-template .team-bio .headshot {
  background: #FFFFFF;
  margin-bottom: 16px;
  padding-top: 16px;
}

@media screen and (min-width: 40em) {
  .about-template {
    background-image: url("/images/hero/bg_boom_crew_inspecting_chimney_med.jpg");
  }
  .about-template .team-section {
    padding-bottom: 53px;
  }
  .about-template .team-bio .headshot {
    margin: 12px 32px 16px 0;
    float: left;
  }
}
@media screen and (min-width: 64em) {
  .about-template {
    background-image: url("/images/hero/bg_boom_crew_inspecting_chimney_lrg.jpg");
  }
}
.case-study-template {
  background-image: url("/images/hero/bg_manlife_crew_cleaning_wall.jpg");
}

@media screen and (min-width: 40em) {
  .case-study-template {
    background-image: url("/images/hero/bg_manlife_crew_cleaning_wall_med.jpg");
  }
}
@media screen and (min-width: 64em) {
  .case-study-template {
    background-image: url("/images/hero/bg_manlife_crew_cleaning_wall_lrg.jpg");
  }
}
.case-study-details-template .hero .inner {
  padding: 0;
}
.case-study-details-template .hero .resource-title {
  font-family: "Korolev W01", Impact, sans-serif;
  font-size: 1rem;
  margin-bottom: 0;
}
.case-study-details-template .hero .heading {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .case-study-details-template .hero .heading {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.case-study-details-template .hero .heading:last-child:not(:first-child) {
  float: right;
}
.case-study-details-template .hero .text {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .case-study-details-template .hero .text {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.case-study-details-template .hero .text:last-child:not(:first-child) {
  float: right;
}
.case-study-details-template .hero .quote-container {
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
@media print, screen and (min-width: 40em) {
  .case-study-details-template .hero .quote-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.case-study-details-template .hero .quote-container:last-child:not(:first-child) {
  float: right;
}
.case-study-details-template .case-study-gallery {
  margin-bottom: 96px;
}
.case-study-details-template .case-study-gallery .inner {
  max-width: 62.5rem;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  float: left;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
}
.case-study-details-template .case-study-gallery .inner::before, .case-study-details-template .case-study-gallery .inner::after {
  display: table;
  content: " ";
}
.case-study-details-template .case-study-gallery .inner::after {
  clear: both;
}
@media print, screen and (min-width: 40em) {
  .case-study-details-template .case-study-gallery .inner {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}
.case-study-details-template .case-study-gallery .inner:last-child:not(:first-child) {
  float: right;
}
.case-study-details-template .case-study-gallery .inner, .case-study-details-template .case-study-gallery .inner:last-child {
  float: none;
}
.case-study-details-template .gallery {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}
@media print, screen and (min-width: 40em) {
  .case-study-details-template .gallery {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
@media print, screen and (min-width: 64em) {
  .case-study-details-template .gallery {
    margin-right: -0.9375rem;
    margin-left: -0.9375rem;
  }
}
.case-study-details-template .gallery-item a {
  display: block;
}
.case-study-details-template .gallery-item img {
  border-radius: 4px;
  border: solid 3px #CDE8F9;
  transition: border 0.3s;
}
.case-study-details-template .gallery-item img:hover, .case-study-details-template .gallery-item img:focus {
  border-color: #61B3E4;
}

@media screen and (min-width: 40em) {
  .case-study-details-template .hero .resource-title {
    font-size: 1.125rem;
  }
  .case-study-details-template .hero .text {
    width: 50%;
  }
  .case-study-details-template .hero .quote-container {
    width: 50%;
  }
  .case-study-details-template .hero.hero-full .text {
    width: 100%;
  }
}
@media screen and (min-width: 64em) {
  .case-study-details-template .hero .text {
    width: 58.3333333333%;
    padding-right: 32px;
  }
  .case-study-details-template .hero .quote-container {
    width: 41.6666666667%;
  }
}
/*------------------------------------*\
    @group Contact Page
\*------------------------------------*/
@media screen and (min-width: 64em) {
  .contact-information .contact-form {
    padding-right: 3rem;
  }
}
/* @end Contact Page */