/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
	margin-top: 143px;
	.inner {
		@include grid-column-row;
	}
	.highlight {
		color: $brand-accent;
	}
	p {
		font-size: rem-calc(19);
		line-height: 1.52;
	}
}

@media screen and #{breakpoint(medium)} {
	.hero {
		margin-top: 136px;
		p {
			font-size: rem-calc(28);
		}
	}
}


//simple hero
.simple-hero {
	margin-bottom: 16px;
	@media screen and #{breakpoint(medium)} {
		margin-bottom: 76px;
	}
}

//Short hero with less margin between content
.close-hero {
	margin-bottom: 8px;
	@media screen and #{breakpoint(medium)} {
		margin-bottom: 24px;
	}
}


/* @end Hero */