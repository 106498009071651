.about-template {
	background-image: url('/images/hero/bg_boom_crew_inspecting_chimney.jpg');
	.team-section {
		padding-bottom: 16px;
	}
	.team-bio {
		h1 {
			color: $brand-accent;
			margin: 0;
		}
		.bio-name h2 {
			font-size: rem-calc(23);
		}
		.team-details {
			overflow: hidden;
			margin-bottom: 24px;
		}
		.headshot {
			background: $white;
			margin-bottom: 16px;
			padding-top: 16px;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.about-template {
		background-image: url('/images/hero/bg_boom_crew_inspecting_chimney_med.jpg');
		.team-section {
			padding-bottom: 53px;
		}
		.team-bio {
			.headshot {
				margin: 12px 32px 16px 0;
				float: left;
			}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.about-template {
		background-image: url('/images/hero/bg_boom_crew_inspecting_chimney_lrg.jpg');
	}
}