/*------------------------------------*\
    @group Contact Page
\*------------------------------------*/
@media screen and #{breakpoint(large)} {
	.contact-information {
		.contact-form {
			padding-right: 3rem;
		}
	}
}
/* @end Contact Page */