/*------------------------------------*\
    @group Tile Cards
\*------------------------------------*/
.tiles {
	@include grid-row($behavior: nest);
}

.tile-container {
	@include grid-column;
	margin-bottom: 32px;
	> a {
		display: block;
	}
}
.tile-image {
	width: 100%;
	height: 240px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	color: $white;
	position: relative;
	.title {
		position: absolute;
		bottom: 24px;
		left: 32px;
		z-index: 10;
		h2 {
			margin: 0;
		}
		p {
			font-family: $alt-font;
			margin: 0;
		}
	}
	&::after {
		content: '';
		background: $brand;
		opacity: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 1;
		transition: opacity .4s;
	}
}
a .tile-image:hover::after,
a .tile-image:focus::after {
	opacity: .78;
	visibility: visible;
}

@media screen and (min-width:31.25em) {
	.tile-container {
		width: grid-column(6);
	}
}

@media screen and (min-width:50em) {
	.tile-container {
		width: grid-column(4);
	}
}

//Case Study Tile Backgrounds
.bg-nitrogen-plant {
	background-image: url('/images/tiles/case-studies/nitrogen_plant.jpg');
}
.bg-hydro-power-plant {
	background-image: url('/images/tiles/case-studies/hydro_power_plant.jpg');
}
.bg-coal-power-plant {
	background-image: url('/images/tiles/case-studies/coal_fired_power_plant.jpg');
}
.bg-pumping-station {
	background-image: url('/images/tiles/case-studies/pumping_station.jpg');
}
.bg-manufacturing {
	background-image: url('/images/tiles/case-studies/manufacturing.jpg');
}
.bg-gas-plant {
	background-image: url('/images/tiles/case-studies/gas_plant.jpg');
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.bg-nitrogen-plant {
		background-image: url('/images/tiles/case-studies/nitrogen_plant@2x.jpg');
	}
	.bg-hydro-power-plant {
		background-image: url('/images/tiles/case-studies/hydro_power_plant@2x.jpg');
	}
	.bg-coal-power-plant {
		background-image: url('/images/tiles/case-studies/coal_fired_power_plant@2x.jpg');
	}
	.bg-pumping-station {
		background-image: url('/images/tiles/case-studies/pumping_station@2x.jpg');
	}
	.bg-manufacturing {
		background-image: url('/images/tiles/case-studies/manufacturing@2x.jpg');
	}
	.bg-gas-plant {
		background-image: url('/images/tiles/case-studies/gas_plant@2x.jpg');
	}
}


/* @end Tile Cards */