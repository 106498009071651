/*------------------------------------*\
    @group Media Object with bg image
\*------------------------------------*/
.bg-media-object {
	background: url('/images/services/bg_asbestos_cleanup_crew.jpg') no-repeat right bottom;
	padding: 72px 0;
	.inner {
		@include grid-row;
	}
	.text {
		@include grid-column;
	}
	h2 {
		font-size: rem-calc(33);
		margin-bottom: 13px;
		display: flex;
		.icon {
			color: $brand-accent;
			margin-right: 12px;
		}
	}
	&.bg-lead-paint {
		background-image: url('/images/services/bg_lead_paint.jpg');
		background-position: left top;
	}
	&.bg-paint-cans {
		background-image: url('/images/services/bg_hazardous_waste.jpg');
		background-position: right bottom;
	}
}

@media screen and #{breakpoint(medium)} {
	.bg-media-object {
		background-image: url('/images/services/bg_asbestos_cleanup_crew_lrg.jpg');
		background-position: center center;
		padding: 111px 0;
		h2 {
			font-size: rem-calc(39);
		}
		&.bg-lead-paint {
			background-image: url('/images/services/bg_lead_paint_lrg.jpg');
			background-position: center center;
		}
		&.bg-paint-cans {
			background-image: url('/images/services/bg_hazardous_waste_lrg.jpg');
			background-position: center center;
		}
	}
}

@media screen and (min-width:48em) {
	.bg-media-object {
		.text {
			width: grid-column(7);
		}
	}
	.bg-media-object.flip {
		.text {
			float: right;
		}
	}
	.bg-media-object.wide {
		.text {
			width: grid-column(8);
		}
	}
}
/* @end Media Object with bg image */

/*------------------------------------*\
    @group Media Object
\*------------------------------------*/
.media-object {
	.text {
		@include grid-column;
	}
	.media {
		@include grid-column;
	}
	.media-list {
		text-align: center;
	}
}

@media screen and #{breakpoint(medium)} {
	.media-object {
		.text {
			width: grid-column(6);
			> *:last-child {
				margin-bottom: 0;
			}
		}
		.media {
			width: grid-column(6);
		}
	}
}
/* @end Media Object */