/*----------------------------------------------------------*\
	Callouts

	Some of the callout styles come from the Foundation
	settings file. The styles listed here apply to the
	default callouts. Additional callout options can be
	seen on the styleguide.

\*----------------------------------------------------------*/

.callout {
	border-radius: 4px;
	overflow: hidden;
	.heading {
		background-color: $brand-accent;
		font-size: rem-calc(28);
		color: $white;
		padding: 22px 19px;
		margin: 0;
	}
	.content {
		background-color: $white;
		color: $gray;
		padding: 25px 30px 1px;
	}
	p {
		font-size: rem-calc(16);
		margin: 0 0 1em;
	}
	ul {
		margin-top: 0;
	}
}

.callout-object {
	.inner {
		@include grid-row;
	}
	.section-heading {
		@include grid-column;
	}
	.content-container {
		@include grid-column;
	}
	.callout-container {
		@include grid-column;
	}
}

@media screen and #{breakpoint(medium)} {
	.callout {
		.heading {
			font-size: rem-calc(35);
			padding: 20px 30px;
		}
	}
	.callout-object {
		.content-container {
			width: grid-column(6);
		}
		.callout-container {
			width: grid-column(6);
		}
	}
}

@media screen and #{breakpoint(large)} {
	.callout-object {
		.content-container {
			width: grid-column(7);
		}
		.callout-container {
			width: grid-column(5);
		}
	}
}


/*------------------------------------*\
    @group Icon Callout
\*------------------------------------*/
.icon-callout {
	.header {
		background: $off-white;
		padding: 20px 15px;
		border-radius: 4px 4px 0 0;
		position: relative;
		.icon {
			background: $brand;
			width: 96px;
			height: 96px;
			font-size: 24px;
			padding: 10px;
			border-radius: 50%;
			line-height: 1;
			position: absolute;
			top: -20px;
			right: 20px;
			display: inline-flex;
			justify-content: center;
			align-items: center;
			&.icon-paper-plane {padding: 11px 13px 13px 11px;}
			&.icon-case-study {padding: 12px;}
			&.icon-trash {padding: 11px 14px 13px 15px;}
			&.icon-users {padding: 11px 10px 13px 12px;}
		}
	}
	.heading {
		font-size: rem-calc(36);
		color: $brand;
		margin-bottom: 0;
	}
	.content {
		background-color: $white;
		font-size: rem-calc(19);
		color: $gray;
		padding: 20px 20px 0;
		border-radius: 0 0 4px 4px;
	}
}

@media screen and #{breakpoint(medium)} {
	.icon-callout {
		.header {
			.icon {
				font-size: 48px;
				top: -39px;
				right: 24px;
				&.icon-paper-plane {padding: 25px 29px 23px 19px;}
				&.icon-case-study {padding: 23px;}
				&.icon-trash {padding: 26px 29px 22px;}
				&.icon-users {padding: 26px 22px 22px;}
			}
		}
		.heading {
			font-size: rem-calc(44);
		}
		.content {
			font-size: rem-calc(23);
			display: flex;
			align-items: center;
			.text {
				padding-right: 60px;
			}
			.action {
				flex-shrink: 0;
			}
		}
	}
	.icon-callout.single {
		.content {
			display: block;
		}
	}
}
/* @end Icon Callout */


/*------------------------------------*\
    @group Callout Section Band
\*------------------------------------*/
.callout-band {
	background-color: $brand-accent;
	margin-top: 50px;
	.inner {
		@include grid-column-row;
	}
	.icon-callout {
		margin-top: -50px;
		.content {
			border-radius: 0;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.callout-band {
		.icon-callout {
			max-width: 625px;
			margin-left: auto;
			margin-right: auto;
			&.wide {
				max-width: 810px;
			}
		}
	}
}
/* @end Callout Section Band */

/*------------------------------------*\
    @group Callout COntainer
\*------------------------------------*/
.callouts {
	@include grid-row;
	margin-top: 69px;
	margin-bottom: 69px;
	.callout-container {
		@include grid-column;
		margin-bottom: 48px;
	}
}

@media screen and #{breakpoint(medium)} {
	.callouts {
		.callout-container {
			width: grid-column(6);
			margin-bottom: 0;
		}
	}
}

/* @end Callout COntainer */