/*----------------------------------------------------------*\
   Images

   Default image styling and various image helpers. The
   floating helpers will not float on smaller views. To
   enable an image floating on all views, use the -all
   suffixed classes.

\*----------------------------------------------------------*/

.page-content {
	img {
		border-radius: 4px;
		box-shadow: 0 6px 10px 0 rgba(0,0,0,0.30);
		margin-bottom: 15px;
		&:hover {
			box-shadow: 0 2px 5px 0 rgba(0,0,0,0.30);
		}
	}
	img.img-border {
		border: 3px solid $light-blue;
		box-shadow: none;
		&:hover {
			border-color: $brand-accent;
		}
	}
}

/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}
.img-full.img-center {
	margin: 0 auto 15px;
	display: block;
}

@media screen and #{breakpoint(medium)} {
	.img-left {
		float: left;
		margin-right: 20px;
	}
	.img-right {
		float: right;
		margin-left: 20px;
	}
}

/* @end */