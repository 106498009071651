/*----------------------------------------------------------*\
    Lists

    In addition to standard list styles, a list with icons
    applied as ::before pseudo-elements is implemented.
    To add additional icons, follow the examples below.
    The split-list-* classes will make your list into
    multiple columns on larger views while remaining a
    single column on smaller views.

\*----------------------------------------------------------*/

ul {
	padding: 0;
	margin: 24px 0;
	@media screen and #{breakpoint(medium)} {
		margin: 27px 0;
	}
	li {
		background: url('/images/icons/primitive-dot.svg') no-repeat left 3px;
		background-size: 12px 12px;
		line-height: 1.5;
		padding: 0 0 0 15px;
		margin: 0 0 .5em;
		list-style: none;
		position: relative;
		&:last-child {
			margin-bottom: 0 !important;
		}
	}
	li ul {
		margin: .666em 0 .75em;
	}
}
ol {
	margin: 24px 0 27px 15px;
	@media screen and #{breakpoint(medium)} {
		margin: 27px 0 27px 15px;
	}
	li {
		line-height: 1.5;
		margin: 0 0 14px;
		counter-increment: list;
		list-style-type: none;
		position: relative;
		&::before {
			content: counter(list) ".";
			width: 26px;
			color: $brand-accent;
			text-align: right;
			left: -32px;
			position: absolute;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	ol {
		list-style: lower-alpha;
	}
}
ul.bare {
	li {
		background: none;
		padding-left: 0;
		margin-bottom: 16px;
		position: relative;
		&::before {
			display: none;
		}
	}
}
ul.icon-list {
	li {
		padding-left: 32px;
	}
	li::before {
		display: none;
	}
	.ico-phone {
		background: url('/images/icons/phone.svg') no-repeat left top;
	}
	.ico-email-outline {
		background: url('/images/icons/email.svg') no-repeat left top;
	}
	.ico-email {
		background: url('/images/icons/email.svg') no-repeat left top;
	}
	.ico-home {
		background: url('/images/icons/home.svg') no-repeat left top;
	}
}

@media screen and #{breakpoint(medium)} {
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
		padding-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		padding-left: 0;
		margin-left: 0;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}

/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		padding: 0;
		margin: 0;
		&::before {
			content: unset;
			font-family: unset;
			font-size: unset;
			display: none;
		}
	}
	li ul {
		margin: 0;
	}
	li ul li {
		background: none;
		margin: 0;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */