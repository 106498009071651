/*------------------------------------*\
    @group Bio Cards
\*------------------------------------*/
.bio-cards {
	@include grid-row($behavior: nest);
	.heading {
		@include grid-column;
	}
	.card-container {
		@include grid-column;
	}
	.card {
		max-width: 220px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 32px;
		.info {
			background: $brand;
			color: $light-blue;
			padding: 12px;
			border-top: solid 2px $brand-accent;
			border-radius: 0 0 4px 4px;
			transition: background .3s, border-color .3s;
		}
		.headshot {
			background: $white;
			padding-top: 12px;
			border-radius: 4px 4px 0 0;
			position: relative;
			img {
				position: relative;
				z-index: 10;
			}
		}
		h3 {
			font-size: rem-calc(28);
			color: $white;
			margin: 0;
		}
		p {
			font-size: rem-calc(16);
			margin: 0;
		}
		&:hover {
			.info {
				background: $brand-accent;
				border-color: brand;
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.bio-cards {
		.heading,
		.card-container {
			width: grid-column(4);
			@include grid-column-end;
		}
		> div:nth-child(4n) {
			clear: left;
		}
		.offset-col-1 {
			margin-left: grid-column(4);
		}
		.card {
			margin-bottom: 56px;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.bio-cards {
		.heading,
		.card-container {
			width: grid-column(3);
			&.offset-left {
				margin-left: 12.5%;
			}
		}
		.offset-col-1 {
			margin-left: grid-column(3);
		}
	}
}

/* @end Bio Cards */