.services-template {
	background-image: url('/images/hero/bg_asbestos_containment_crew.jpg');
}

@media screen and #{breakpoint(medium)} {
	.services-template {
		background-image: url('/images/hero/bg_asbestos_containment_crew_med.jpg');
	}
}

@media screen and #{breakpoint(large)} {
	.services-template {
		background-image: url('/images/hero/bg_asbestos_containment_crew_lrg.jpg');
	}
}