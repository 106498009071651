/*----------------------------------------------------------*\
   Blockquote

   Blockquote styling with large quote image. Background
   color can be overriden with the background (and gradient)
   classes listed on the styleguide.

\*----------------------------------------------------------*/


blockquote {
	font-family: $alt-font;
	font-size: rem-calc(19);
	border-radius: 0;
	padding-left: 35px;
	padding-top: 10px;
	margin: 20px 0;
	position: relative;
	&::before {
		content: '';
		background: url('/images/icons/quote.svg') no-repeat left top;
		background-size: contain;
		width: 37px;
		height: 45px;
		color: $brand-accent;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}
	cite {
		font-family: $main-font;
		font-size: rem-calc(16);
		font-style: normal;
		font-weight: $alt-bold;
		color: $brand-accent;
		line-height: 1.1;
		display: inline-block;
		float: right;
		.title {
			font-weight: $alt-normal;
			display: block;
		}
		&::before {
			content: '';
		}
	}
	.quote-wrap {
		padding: 0;
		margin: 0 0 10px;
		p {
			line-height: 1.25;
		}
	}
}

@media screen and #{breakpoint(medium)} {
	blockquote {
		font-size: rem-calc(23);
		padding-top: 15px;
		padding-left: 45px;
		&::before {
			font-size: 28px;
		}
	}
}

//Simple in-content quote
.simple-quote {
	font-size: rem-calc(24);
	line-height: 1.17;
	border-left: solid 10px $brand-accent;
	padding: 0 0 0 16px;
	&::before {
		display: none;
	}
	&.large {
		font-size: rem-calc(24);
		color: $light-blue;
		line-height: 1.17;
	}
}

@media screen and #{breakpoint(medium)} {
	.simple-quote {
		font-size: rem-calc(28);
		line-height: 1.29;
		padding-left: 24px;
		&.quote-right {
			max-width: 400px;
			margin-left: 32px;
			float: right;
		}
		&.large {
			font-size: rem-calc(39);
			line-height: 1.08;
		}
		&.small {
			font-size: rem-calc(24);
			line-height: 1.29;
		}
	}
}