/*----------------------------------------------------------*\
    Helpers

    This file contains most of the available helper
    utilities (not including base Foundation). Use these
    when small tweaks are needed on existing pieces that
    don't warrant a full semantic CSS process.

\*----------------------------------------------------------*/


/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/

.text-xlrg {font-size:1.4375em !important}
.text-lrg {font-size:1.25em !important}
.text-sml {font-size:.75em !important}

.text-shadow {text-shadow: $text-shadow !important;}
.text-shadow-light {text-shadow: $text-shadow-light !important;}

@media screen and #{breakpoint(medium)} {
    .text-xlrg {font-size:1.62em !important}
    .text-lrg {font-size:1.27em !important}
    .text-sml {font-size:.75em !important}
}


/* @end */


/*------------------------------------*\
    @group Text Colors
\*------------------------------------*/

.highlight {
    color: $brand;
}
.highlight-alt {
    color: $brand-alt;
}

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
}

/* @end */


/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/

//Background colors from _variables
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

//Background gradients from _variables
@each $name, $color in $gradients {
  .bg-gradient-#{$name} {
    background: $color !important;
  }
}

.box-shadow {box-shadow: $box-shadow;}
.box-shadow-light {box-shadow: $box-shadow-light;}

.bg-round {border-radius: $border-round;}

/* @end */


/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
.mb-0 {margin-bottom: 0 !important;}
.mb-5 {margin-bottom: 5px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-35 {margin-bottom: 35px !important;}
.mb-40 {margin-bottom: 40px !important;}

.mt-0 {margin-top: 0 !important;}
.mt-5 {margin-top: 5px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-15 {margin-top: 15px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-25 {margin-top: 25px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-35 {margin-top: 35px !important;}
.mt-40 {margin-top: 40px !important;}

.stacked {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
/* @end */

/*------------------------------------*\
    @group Responsive Map
\*------------------------------------*/
.map-embed {
    height: 0;
    position: relative;
    padding-bottom: 75%; // This is the aspect ratio
    overflow: hidden;
}
.map-embed iframe {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}
/* @end Responsive Map */


/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/

@each $name, $color in $social {
  .bg-#{$name} {
    background-color: $color !important;
  }
}

@each $name, $color in $social {
  .text-#{$name} {
    color: $color !important;
  }
}

/* @end */