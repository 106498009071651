.double-column-section {
	.inner {
		@include grid-row;
	}
	.first-col {
		background-color: $brand-alt;
		@include grid-column;
		padding-top: 32px;
		padding-bottom: 48px;
	}
	.last-col {
		background-color: $dark-gray;
		@include grid-column;
		padding-top: 32px;
		padding-bottom: 48px;
	}
}

@media screen and #{breakpoint(medium)} {
	.double-column-section {
		background: linear-gradient(to right, $brand-alt 0%, $brand-alt 50%, $dark-gray 50%,  $dark-gray 100%);
		padding: 82px 0;
		.first-col {
			background-color: transparent;
			width: grid-column(6);
			padding-top: 0;
			padding-bottom: 0;
		}
		.last-col {
			background-color: transparent;
			width: grid-column(6);
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

@media screen and #{breakpoint(large)} {
	.double-column-section {
		.first-col {
			padding-right: 64px;
		}
		.last-col {
			padding-left: 64px;
		}
	}
}