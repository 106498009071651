/*----------------------------------------------------------*\
    Variables

    This file contains all (non-Foundation) globally-scoped
    variables used throughout the Sass files. We strongly
    encourage sticking to these variables instead of
    hard-coded values as often as possible.

\*----------------------------------------------------------*/

//Font families
$sans-serif-font: 'Neue Helvetica W01', Helvetica, 'Trebuchet MS', Arial, sans-serif;
$sans-serif-alt-font: 'Korolev W01', Impact, sans-serif;
$serif-font: sans-serif;
$icon-font: 'PtiIndustrial';
$heading-font: $sans-serif-alt-font;
$main-font: $sans-serif-font;
$alt-font: $heading-font;

//Font weights
$main-normal: 400;
$main-bold: 700;
$alt-normal: 500;
$alt-bold: 700;
$heading-normal: $alt-normal;
$heading-bold: $alt-bold;

//Font sizes
$fsize-large: 18px;

//Styleguide colors
$brand:				 #193769;
$brand-accent:		 #61B3E4;
$brand-alt:			 #151720;
$brand-alt-accent:	 #979DA8;
$green:		         #8CAB14;
$orange:             #F67F00;
$dark-blue:			 #222732;
$light-blue:       	 #CDE8F9;
$white:              #FFFFFF;
$off-white:          #E8EBF1;
$light-gray:         #C4CDCF;
$gray:               #414247;
$dark-gray:          #1C1819;
$black:              #000000;

$colors: (
	'brand':			 $brand,
	'brand-accent':		 $brand-accent,
	'brand-alt':		 $brand-alt,
	'brand-alt-accent':	 $brand-alt-accent,
	'green':		     $green,
	'orange':            $orange,
	'dark-blue':		 $dark-blue,
	'light-blue':        $light-blue,
	'white':             $white,
	'off-white':         $off-white,
	'light-gray':        $light-gray,
	'gray':              $gray,
	'dark-gray':         $dark-gray,
	'black':             $black
);

//Gradients, for use on backgrounds only
$gradients: (
	'white': linear-gradient(180deg, rgba(231, 231, 231, 1) 0%, rgba(255, 255, 255, 1) 100%)
);

//Transparent background colors
$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);

// Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $off-white;

//Link colors
$link-base: $green;
$link-active: $brand-accent;

//Button colors
$button-colors: (
	'green': $green,
	'orange': $orange,
	'brand': $brand,
	'brand-alt-accent': $brand-alt-accent,
	'brand-alt': $brand-alt
);

//Social media brand colors
$social: (
	'twitter': 		 #00aced,
	'facebook': 	 #3b5998,
	'googleplus': 	 #dd4b39,
	'pinterest': 	 #cb2027,
	'linkedin': 	 #007bb6,
	'youtube': 		 #bb0000,
	'vimeo': 		 #aad450,
	'instagram': 	 #517fa4,
	'quora': 		 #a82400,
	'foursquare': 	 #0072b1,
	'stumbleupon': 	 #EB4823,
	'soundcloud': 	 #ff3a00
);

//Shadows
$text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
$text-shadow-light: 0 2px 2px rgba(255, 255, 255, 0.53);
$box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);;
$box-shadow-light: 0 5px 10px rgba(255, 255, 255, 0.2);

//Rounded corners
$border-round: 1000px;
$border-radius: 100px;

//layout
$header-height: (
	'small': 72px,
	'medium': 92px
);

$header-height-bg: (
	'medium': 130px
);