@font-face {
    font-family: 'Neue Helvetica W01';
    src: url('/fonts/helvetica_neue_bold.woff2') format('woff2'),
        url('/fonts/helvetica_neue_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Helvetica W01';
    src: url('/fonts/helvetica_neue_roman.woff2') format('woff2'),
        url('/fonts/helvetica_neue_roman.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Korolev W01';
    src: url('/fonts/korolev_condensed_bold.woff2') format('woff2'),
        url('/fonts/korolev_condensed_bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Korolev W01';
    src: url('/fonts/korolev_condensed_medium.woff2') format('woff2'),
        url('/fonts/korolev_condensed_medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}