/*----------------------------------------------------------*\
    Header

    All styles for the main page header are below. This
    includes the navigation elements as well.

\*----------------------------------------------------------*/


.page-header {
	background: $dark-blue;
	width: 100%;
	height: map-get($header-height,small);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100;
    .inner {
    	@include flex-grid-row;
    }
    .logo {
    	padding-top: 12px;
    	padding-bottom: 12px;
    	@include flex-grid-column(shrink);
    }
    .mobile-menu-toggle {
    	@include flex-grid-column;
    	align-self: center;
    }
    .mobile-nav {
    	background: $dark-blue;
    	width: 100%;
    	//height: 100vh;
    	padding: 18px 18px 48px;
    	display: none;
    }
	.mobile-menu-toggle {
		font-family: $alt-font;
		font-size: rem-calc(16);
		font-weight: 700;
		text-transform: uppercase;
		text-align: right;
		position: relative;
		a {
			color: $white;
			&:hover,
			&:focus {
				color: $brand-accent;
				text-decoration: none;
			}
		}
		.nav-label {
			.close-text {
				font-size: 32px;
			}
			.close-text,
			&.open .open-text {
				display: none;
			}
			&.open .close-text {
				display: block;
			}
		}
	}
	.mobile-menu-anchor {
		padding: 0;
	}

}

.nav-slide-in {
	@include mui-slide(in, left, $duration: .2s);
}
.nav-slide-out {
	@include mui-slide(out, right, $duration: .15s);
}

@media screen and (max-width:640px) {
	.large-nav {
		display: none;
	}
}

@media screen and #{breakpoint(medium)} {
	.page-header {
		height: map-get($header-height,medium);
		.logo {
			padding-bottom: 18px;
			padding-top: 18px;
			img {
				max-height: 56px;
			}
		}
		.large-nav {
			width: auto;
			padding-top: 0;
			padding-bottom: 0;
			@include flex-grid-column;
		}
		.mobile-menu-toggle,
		.mobile-nav {
			display: none !important;
		}
	}
}

/*------------------------------------*\
    @group Navigation
\*------------------------------------*/
.main-nav {
	display: block;
	ul {
		height: 100%;
		font-family: $alt-font;
		font-size: rem-calc(32);
		text-align: center;
		line-height: 1;
		overflow: hidden;
		li {
			margin-bottom: 42px;
			line-height: inherit;
		}
		a {
			color: $brand-accent;
			line-height: inherit;
			&:hover, &:focus, &.selected {
				color: $white;
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.main-nav {
		height: map-get($header-height,medium);
		ul {
			font-size: rem-calc(24);
			text-align: right;
			display: flex;
			justify-content: flex-end;
			li {
				line-height: inherit;
				margin-left: 28px;
				margin-bottom: 0;
				display: flex;
				align-items: center;
				&:first-child {
					margin-left: 0;
				}
			}
			a {
				&::before {
					content: '';
					width: 100%;
					display: block;
					position: absolute;
					top: 0;
					border-top: solid 5px $brand-alt-accent;
					transition: border .2s;
					opacity: .5;
				}
				&.selected {
					color: $white;
				}
				&.selected::before {
					transition: none;
				}
				&:hover::before,
				&.selected::before {
					border-top: solid 20px $white;
					opacity: 1;
				}
			}
		}
	}
}

/* @end Navigation */

/*------------------------------------*\
    @group Header with background images
\*------------------------------------*/
.bg-template {
	.page-header {
		background: none;
		transition: background .3s;
	}
	.page-header.open {
		background: $dark-blue;
	}
}


@media screen and #{breakpoint(medium)} {
	.bg-template {
		.page-header {
			height: map-get($header-height-bg,medium);
			transition: background-color .3s;
			&.open {
				background-color: $dark-blue;
			}
		}
		.main-nav {
			height: map-get($header-height-bg,medium);
		}
	}
}
/* @end Header with background images */