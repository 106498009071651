/*----------------------------------------------------------*\
    Main Content Section

    This file contains styles for the main content section,
    including sidebars.

\*----------------------------------------------------------*/

.page-content {
    padding: 32px 0 32px;
    margin-top: 100px;
    .inner {
    	@include grid-column-row;
    }
}
