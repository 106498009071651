/*----------------------------------------------------------*\
   Spacing Sections

   Sections can be used to increase whitespace between
   content sections as needed. The recommended method is
   wrapping the content in a div and applying the class
   with the spacing you want. The -full suffix will apply
   padding to the top and bottom which is well-suited for
   content areas that span the full width of the screen
   and use a background color.

   All sections are sized in 'em' which means they will
   resize their spacing according to the font-size of the
   parent container. Smaller font-size means less spacing
   and larger font-size increases the spacing.

\*----------------------------------------------------------*/

.content-section-full {
	padding: 48px 0;
}
.intro-section {
	padding-bottom: 2em;
}
.content-section {
	padding-bottom: 1.5em;
}
.content-sub-section {
	padding-bottom: 1em;
}
.texture-concrete {
	background: url('/images/cssimg/bg_concrete.jpg') repeat $white;
	color: $gray;
	h2 {
		color: $brand;
	}
}
.section-inner {
	@include grid-column-row;
}


@media screen and #{breakpoint(medium)} {
	.content-section-full {
		padding: 108px 0;
	}
	.intro-section {
		padding-bottom: 0 0 3.5em;
	}
	.content-section {
		padding-bottom: 2.5em;
	}
	.content-sub-section {
		padding-bottom: 1.5em;
	}
}