/*----------------------------------------------------------*\
    Buttons

    Most button styles below can be combined.
    For example, you could have small brand accent button by
	combining the .button.brand-accent.small classes.

\*----------------------------------------------------------*/


.button,
form input[type="submit"] {
	background-color: $brand-accent;
	font-family: $alt-font;
	font-size: rem-calc(20);
	font-weight: 700;
	color: $white;
	padding: 15px 30px;
	margin-bottom: 1.25rem;
	border-radius: $border-radius;
	border: none;
	text-transform: uppercase;
	display: inline-block;
	line-height: 1.2;
	&:hover,
	&:focus {
		background-color: darken($brand-accent, 10%);
		color: $white;
		text-decoration: none;
	}
}
.button.small {
	font-size: rem-calc(16);
	padding: 10px 20px;
}
.button.large {
	font-size: rem-calc(24);
	padding: 20px 40px;
}
.button.tiny {
	font-size: rem-calc(14);
	padding: 5px 15px;
}
.button.hollow {
	background: none;
	color: $white;
	border: solid 3px $white;
	&:hover,
	&:focus {
		background-color: $white;
		color: $brand;
	}
	&.brand {
		background: none;
		color: $brand;
		border-color: $brand;
		&:hover,
		&:focus {
			background-color: $brand;
			color: $white;
		}
	}
}
.button.ico {
	.icon {
		margin-right: 7px;
	}
	&.large .icon {
		margin-right: 10px;
	}
}

//Colors
@each $name, $color in $button-colors {
  .button.#{$name} {
    background-color: $color;
    &:hover,
    &:focus {
    	background-color: darken($color, 10%);
    }
  }
}