.case-study-template {
	background-image: url('/images/hero/bg_manlife_crew_cleaning_wall.jpg');
}

@media screen and #{breakpoint(medium)} {
	.case-study-template {
		background-image: url('/images/hero/bg_manlife_crew_cleaning_wall_med.jpg');
	}
}

@media screen and #{breakpoint(large)} {
	.case-study-template {
		background-image: url('/images/hero/bg_manlife_crew_cleaning_wall_lrg.jpg');
	}
}

.case-study-details-template {
	.hero {
		.inner {
			padding: 0;
		}
		.resource-title {
			font-family: $alt-font;
			font-size: rem-calc(16);
			margin-bottom: 0;
		}
		.heading {
			@include grid-col;
		}
		.text {
			@include grid-col;
		}
		.quote-container {
			@include grid-col;
		}
	}
	.case-study-gallery {
		margin-bottom: 96px;
		.inner {
			@include grid-column-row;
		}
	}
	.gallery {
		@include grid-row-nest;
	}
	.gallery-item {
		a {
			display: block;
		}
		img {
			border-radius: 4px;
			border: solid 3px $light-blue;
			transition: border .3s;
			&:hover, &:focus {
				border-color: $brand-accent;
			}
		}
	}
}

@media screen and #{breakpoint(medium)} {
	.case-study-details-template {
		.hero {
			.resource-title {
				font-size: rem-calc(18);
			}
			.text {
				width: grid-col(6);
			}
			.quote-container {
				width: grid-col(6);
			}
		}
		.hero.hero-full {
			.text {
				width: grid-col(12);
			}
		}
	}
}

@media screen and #{breakpoint(large)} {
	.case-study-details-template {
		.hero {
			.text {
				width: grid-col(7);
				padding-right: 32px;
			}
			.quote-container {
				width: grid-col(5);
			}
		}
	}
}