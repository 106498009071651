/*----------------------------------------------------------*\
    Basestyles

    Base styles that are too small for individual files. The
    animate classes listed here will be the default for
    animated elements unless overriden.

\*----------------------------------------------------------*/

body {
	background: #201e1e;
	font-family: $main-font;
	color: $main-color;
	padding-top: map-get($header-height,small);
	@media screen and #{breakpoint(medium)} {
		font-size: rem-calc(18);
		padding-top: map-get($header-height,medium);
	}
}
//Body class for page background image
.bg-template {
	background: no-repeat center top $dark-gray;
}
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 24px;
	@media screen and #{breakpoint(medium)} {
		margin: 0 0 27px;
	}
	&.intro {
		font-size: rem-calc(19);
	}
}
a {
	color: $link-base;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $link-active;
		text-decoration: none;
	}
}
hr {
	margin: 1.25rem 0;
}

@media screen and #{breakpoint(medium)} {
	p.intro {
		font-size: rem-calc(23);
	}
}
/* @end */

