/*----------------------------------------------------------*\
    Page Footer Section

    This file contains all styles for the footer section.

\*----------------------------------------------------------*/

.page-footer {
    font-size: rem-calc(14);
    h2 {
        font-size: rem-calc(20);
        margin-bottom: 12px;
    }
    .inner {
        @include grid-row;
        > div {
            padding-top: 28px;
            padding-bottom: 8px;
        }
    }
    .first-section {
        background: #293443;
        @include grid-column;
    }
    .last-section {
        background: $dark-blue;
        @include grid-column;
    }
    .logo {
        margin-bottom: 18px;
    }
    .copyright {
        font-size: rem-calc(12);
        text-align: center;
    }
    .footer-nav {
        ul {
            margin-top: 0;
        }
        li {
            width: 50%;
            margin: 0 0 4px;
            padding-left: 0;
            padding-right: 10px;
            display: inline-block;
            &::before {
                display: none;
            }
        }
        a {
            color: $brand-alt-accent;
            &:hover, &:focus {
                color: $white;
            }
        }
    }
    .footer-image-list {
        ul {
            margin-top: 0;
            li {
                background: none;
            }
        }
        li {
            padding-left: 0;
            margin: 0 24px 12px 0;
            display: inline-block;
            &::before {
                display: none;
            }
            &::last-child {
                margin-right: 0;
            }
        }
    }
    .heading {
        h2 {
            display: flex;
            align-items: center;
            &::after {
                content: '';
                background: #2b313d;
                width: 100%;
                height: 2px;
            }
            span {
                padding-right: 10px;
                flex-shrink: 0;
            }
        }
    }

}

@media screen and #{breakpoint(medium)} {
    .page-footer {
        background: linear-gradient(to right, #293443 0%, #293443 50%, $dark-blue 50%,  $dark-blue 100%);
        .first-section {
            width: grid-column(6);
            background: none;
        }
        .last-section {
            width: grid-column(6);
            background: none;
        }
        .copyright {
            text-align: left;
            br {
                display: none;
            }
        }
    }
}

@media screen and (min-width:56.25em) {
    .page-footer {
        .inner > div {
            padding-top: 48px;
            padding-bottom: 28px;
        }
        .first-section {
            padding-right: 76px;
        }
        .last-section {
            padding-left: 38px;
        }
        .footer-nav {
            width: 50%;
            padding-right: 32px;
            float: left;
            li {
                width: 100%;
                padding-left: 0;
                display:block;
                &::before {
                    display: none;
                }
                &::last-child {
                    margin-right: 0;
                }
            }
        }
        .footer-image-list {
            width: 50%;
            float: left;
        }
        .copyright {
            float: left;
        }
    }
}